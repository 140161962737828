import React, { useState, MouseEvent, FC, ReactNode } from 'react';
import MuiPopover from '@mui/material/Popover';
import clsx from 'clsx';

import ButtonAction from '../ButtonAction';

import { useStyles } from './style';
import { iconButtonClasses } from '@mui/material';

interface Params {
	content: JSX.Element | JSX.Element[];
	buttonText?: string | JSX.Element;
	icon?: ReactNode;
	buttonClassName?: string;
	className?: string;
}

const Popover: FC<Params> = ({
	content,
	buttonText,
	icon: Icon,
	buttonClassName = '',
	className,
}) => {
	const { container } = useStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleOpen = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div className={clsx(container, className)}>
			{buttonText && (
				<ButtonAction
					className={buttonClassName}
					aria-describedby={id}
					onClick={handleOpen}
				>
					{buttonText}
				</ButtonAction>
			)}
			{Icon && iconButtonClasses}
			<MuiPopover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				{content}
			</MuiPopover>
		</div>
	);
};

export default Popover;
