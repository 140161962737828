import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Roles } from 'constants/roles';

import { AppContext } from 'components/AppContextProvider/context';
import SettingsPage from 'components/common/SettingsPage';
import UserManagementSection from '../UserManagementSection';

const CompanyPage = () => {
	const navigate = useNavigate();
	const { user, users, handleSetCompany } = useContext(AppContext);
	if (!user?.roles.includes(Roles.COMPANY_ADMIN)) {
		navigate('/');
	}

	const { company } = user!;

	return (
		<SettingsPage title={company.name}>
			{company && (
				<UserManagementSection
					company={company}
					users={users[company.id] ?? []}
					onSetCompany={handleSetCompany}
				/>
			)}
		</SettingsPage>
	);
};

export default CompanyPage;
