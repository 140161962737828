import { TextField, TextFieldProps } from '@mui/material';
import React, { FC } from 'react';

const TextFieldOutlined: FC<TextFieldProps> = ({
	children,
	...rest
}): JSX.Element => {
	return <TextField variant='outlined' margin='dense' {...rest} />;
};

export default React.memo(TextFieldOutlined);
