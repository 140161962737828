import { useEffect, useState } from 'react';

import ICase from 'models/Case';
import IOrder from 'models/Order';
import getFilterFromLocalStorage, {
	CaseYearFilter,
	CASE_YEAR_FILTER_NAME,
} from './util/getFilterFromLocalStorage';
import { SelectChangeEvent } from '@mui/material';

const thisYear = new Date().getFullYear();
const onlyDesiredCases = (filter: CaseYearFilter) => (c: ICase) => {
	if (!filter.useFilter) return true;

	const value = c[`${filter.property} Date`];
	return !value || thisYear - new Date(value).getFullYear() <= filter.years;
};

export const useCaseData = (
	initialCases: ICase[],
	filter: CaseYearFilter,
	onUpdateOrder: (
		property: string,
		newValue: string | boolean | ICase[]
	) => void
) => {
	const [cases, setCases] = useState<ICase[]>(initialCases);
	const [displayedCases, setDisplayedCases] = useState<ICase[]>(
		cases.filter(onlyDesiredCases(filter))
	);

	const handleUpdateCase = (index: number) => (newCase: ICase) => {
		onUpdateOrder(
			'cases',
			cases.map((c: ICase, i: number) => (i === index ? newCase : c))
		);
	};

	const handleOrderChange = (order: IOrder) => {
		setCases(order.cases);
	};

	const toggleCompleteOrder = (order: IOrder) => {
		onUpdateOrder('isCompleted', !order.isCompleted);
	};

	useEffect(() => {
		setCases(initialCases);
		setDisplayedCases(initialCases.filter(onlyDesiredCases(filter)));
	}, [initialCases, filter]);

	return {
		cases,
		displayedCases,
		handleUpdateCase,
		toggleCompleteOrder,
		handleOrderChange,
	};
};

export const useCaseYearFilter = () => {
	const [filter, setFilter] = useState(getFilterFromLocalStorage());

	const handleChangeYears = (e: React.ChangeEvent<HTMLInputElement>) =>
		setFilter((filter) => ({ ...filter, years: parseInt(e.target.value) }));

	const handleChangeProperty = (
		e: SelectChangeEvent<'Disposition' | 'Filing'>
	) =>
		setFilter((filter) => ({
			...filter,
			property: e.target.value as 'Disposition' | 'Filing',
		}));

	const handleChangeUseFilter = (e: React.ChangeEvent<HTMLInputElement>) =>
		setFilter((filter) => ({
			...filter,
			useFilter: e.target.checked,
		}));

	useEffect(
		() =>
			localStorage.setItem(CASE_YEAR_FILTER_NAME, JSON.stringify(filter)),
		[filter]
	);

	return {
		filter,
		handleChangeYears,
		handleChangeProperty,
		handleChangeUseFilter,
	};
};
