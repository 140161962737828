import { SnackBarContextActions } from 'components/SnackBarContextProvider/context';
import { Roles } from 'constants/roles';
import { ICompany, IUserLicense, LicenseStatuses } from 'models/Company';
import IUser from 'models/User';
import { Repo } from 'network/Repo';
import { useMemo } from 'react';

type UseLicenses = (props: {
	company: ICompany;
	users: IUser[];
}) => {
	usersWithLicenses: {
		license: IUserLicense;
		id: string;
		name: string;
		firstName: string;
		lastName: string;
		email: string;
		roles: Roles[];
		company: ICompany;
		licenseId: string;
	}[];
	inactiveLicenses: IUserLicense[];
	sentLicenses: IUserLicense[];
	availableLicenses: IUserLicense[];
};

export const useLicenses: UseLicenses = ({ company, users }) => {
	const usersWithLicenses =
		company.licenses
			?.filter(
				(license: IUserLicense) =>
					license.status === LicenseStatuses.IN_USE
			)
			.map((license: IUserLicense) => ({
				...users.find(
					(thisUser: IUser) => thisUser.licenseId === license.id
				)!,
				license,
			})) ?? [];

	const inactiveLicenses =
		company.licenses?.filter(
			(license: IUserLicense) =>
				license.status === LicenseStatuses.INACTIVE
		) ?? [];

	const sentLicenses =
		company.licenses?.filter(
			(license: IUserLicense) =>
				license.status === LicenseStatuses.INVITE_SENT
		) ?? [];

	const availableLicenses =
		company.licenses?.filter(
			(license: IUserLicense) =>
				license.status === LicenseStatuses.AVAILABLE
		) ?? [];

	return {
		usersWithLicenses,
		inactiveLicenses,
		sentLicenses,
		availableLicenses,
	};
};

type UseLicenseHandlers = (props: {
	companyId: string;
	snackBar: SnackBarContextActions;
	users: IUser[];
	availableLicenses: IUserLicense[];
	handleSetUsers: (companyId: string, users: IUser[]) => void;
	handleSetCompany: (company: ICompany) => void;
}) => {
	handleInvite: (e: React.SyntheticEvent<HTMLFormElement>) => Promise<void>;
	handleRevokeInvite: (licenseId: string) => () => Promise<void>;
	handleResendInvite: (licenseId: string) => () => Promise<void>;
	handleLicenseActivation: (
		licenseId: string,
		activationStatus: boolean
	) => () => Promise<void>;
	handleChangeRole: (
		companyId: string,
		userId: string,
		roles: Roles[],
		role: Roles
	) => (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export const useLicenseHandlers: UseLicenseHandlers = ({
	companyId,
	snackBar,
	users,
	availableLicenses,
	handleSetUsers,
	handleSetCompany,
}) => {
	const handleInvite = async (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		const email = data.get('email') as string;
		const updatedCompany = await Repo.inviteUser(
			companyId,
			email,
			availableLicenses[0].id,
			snackBar.showError
		);
		handleSetCompany(updatedCompany);
		snackBar.showSuccess('Invite Sent');
	};

	const handleRevokeInvite = (licenseId: string) => async () => {
		const updatedCompany = await Repo.revokeInvite(
			companyId,
			licenseId,
			snackBar.showError
		);
		handleSetCompany(updatedCompany);
		snackBar.showSuccess('Invite Revoked');
	};

	const handleResendInvite = (licenseId: string) => async () => {
		const updatedCompany = await Repo.resendInvite(
			companyId,
			licenseId,
			snackBar.showError
		);
		handleSetCompany(updatedCompany);
		snackBar.showSuccess('Invite Sent');
	};

	const handleLicenseActivation = (
		licenseId: string,
		activationStatus: boolean
	) => async () => {
		const updatedCompany = activationStatus
			? await Repo.reactivateLicense(
					companyId,
					licenseId,
					snackBar.showError
			  )
			: await Repo.deactivateLicense(
					companyId,
					licenseId,
					snackBar.showError
			  );
		handleSetCompany(updatedCompany);
		snackBar.showSuccess(
			activationStatus ? 'User Reactivated' : 'User Deactivated'
		);
	};

	const handleChangeRole = (
		companyId: string,
		userId: string,
		roles: Roles[],
		role: Roles
	) => async (e: React.ChangeEvent<HTMLInputElement>) => {
		const newRoles = e.target.checked
			? roles.concat(role).sort()
			: roles.filter((r) => r !== role);

		const updateUsersWithUpdatedUser = (updatedUser: IUser) =>
			handleSetUsers(
				companyId,
				users.map((existingUser) =>
					existingUser.id === updatedUser.id
						? updatedUser
						: existingUser
				)
			);

		await Repo.updateRoles(
			userId,
			newRoles,
			updateUsersWithUpdatedUser,
			snackBar.showError
		);
		snackBar.showSuccess('User Roles Updated');
	};

	return {
		handleInvite,
		handleRevokeInvite,
		handleResendInvite,
		handleLicenseActivation,
		handleChangeRole,
	};
};

type UseNumOfAdmins = (users: IUser[]) => number;

export const useNumOfAdmins: UseNumOfAdmins = (users) => {
	return useMemo(
		() =>
			users.filter((thisUser) =>
				thisUser.roles.includes(Roles.COMPANY_ADMIN)
			).length,
		[users]
	);
};
