import React, { FC } from 'react';
import { ICompany } from 'models/Company';
import { Box } from '@mui/material';

import { useSnackBar } from 'components/SnackBarContextProvider/hooks';
import { CountyPermissions, GeneralPermissions } from 'constants/permissions';
import { Repo } from 'network/Repo';

import PermissionSwitch from './PermissionSwitch';
import { useStyles } from './style';
import { toPermissionObjects, byName } from './utils';

interface Props {
	company: ICompany;
}

const PermissionManagementSection: FC<Props> = ({ company }) => {
	const {
		container,
		innerContainer,
		generalPermissionsContainer,
		countyPermissionsOuterContainer,
		countyPermissionsInnerContainer,
	} = useStyles();
	const snackBar = useSnackBar();
	const generalPermissions = Object.keys(GeneralPermissions)
		.map(toPermissionObjects)
		.sort(byName);
	const countyPermissions = Object.keys(CountyPermissions)
		.map(toPermissionObjects)
		.sort(byName);

	const handleChangePermission = (perm: string) => async (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const newPermissions = e.target.checked
			? company.permissions.concat(perm).sort()
			: company.permissions.filter((p) => p !== perm);

		company.permissions = newPermissions;

		await Repo.updateCompany(company, snackBar.showError);
		snackBar.showSuccess('Permission Updated');
	};

	return (
		<Box className={container}>
			<h2>Permissions</h2>
			<Box className={innerContainer}>
				<Box className={generalPermissionsContainer}>
					<h5>GENERAL</h5>
					{generalPermissions.map((perm) => (
						<PermissionSwitch
							display={perm.display}
							value={perm.value}
							checked={company.permissions.includes(perm.value)}
							onChange={handleChangePermission(perm.value)}
							key={perm.value}
						/>
					))}
				</Box>
				<Box className={countyPermissionsOuterContainer}>
					<h5>COUNTIES</h5>
					<Box className={countyPermissionsInnerContainer}>
						{countyPermissions.map((perm) => (
							<PermissionSwitch
								display={perm.display}
								value={perm.value}
								checked={company.permissions.includes(
									perm.value
								)}
								onChange={handleChangePermission(perm.value)}
								key={perm.value}
							/>
						))}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default PermissionManagementSection;
