import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { flexColCenterCenter, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullHeight,
		...fullWidth,
		...flexColCenterCenter,
	},
	icon: {
		width: '128px !important',
		height: '128px !important',
		color: theme.palette.custom.common.lightGray
	}
}));