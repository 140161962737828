import { alpha, createTheme, darken, lighten, ThemeOptions } from '@mui/material';
import {
	ColorPartial,
	CommonColors,
	SimplePaletteColorOptions,
	PaletteColor,
} from '@mui/material/styles/createPalette';
import { colors } from '../constants/colors';

declare module '@mui/material/styles' {
	interface CommonColorsVariants {
		primary: string;
		light: string;
		dark: string;
	}
	interface BrandingCommonColors extends CommonColors {
		lightGray: string;
		darkGray: string;
		green: CommonColorsVariants;
		purple: CommonColorsVariants;
		pink: string;
		red: CommonColorsVariants;
	}
	interface BrandingColorClassificationOptions {
		[index: string]: SimplePaletteColorOptions | ColorPartial;
	}
	interface BrandingColorClassification {
		[index: string]: PaletteColor;
	}
	interface CustomPalette {
		common: BrandingCommonColors;
	}
	interface CustomPaletteOptions extends PaletteOptions {
		common?: BrandingCommonColors;
	}
	interface PaletteOptions {
		custom?: CustomPaletteOptions;
	}
	interface Palette {
		custom: CustomPalette;
	}
}

export const baseTheme: ThemeOptions = {
	palette: {
		secondary: {
			main: colors.orange,
		},
		text: {
			primary: colors.darkGray,
		},
		custom: {
			common: {
				black: colors.black,
				white: colors.white,
				lightGray: colors.lightGray,
				darkGray: colors.darkGray,
				green: {
					primary: colors.green,
					light: lighten(colors.green, .3),
					dark: darken(colors.green, .3),
				},
				pink: colors.pink,
				red: {
					primary: colors.red,
					light: lighten(colors.red, .3),
					dark: darken(colors.red, .3),
				},
				purple: {
					primary: colors.purple,
					light: lighten(colors.purple, .3),
					dark: darken(colors.purple, .3),
				}
			},
		},
	},
	typography: {
		fontFamily: [
			'Rubik',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'outlined' && {
						margin: `0 0 0 ${theme.spacing(1)}px`,
						color: 'white',
						backgroundColor: theme.palette.custom.common.black,
						border: 'unset',
						'&:hover': {
							background: lighten(theme.palette.custom.common.black, .2),
							border: 'unset',
						},
						// border: '1px solid #111',
						// color: '#111',
						// backgroundColor: '#fff',
						// '&:hover': {
						// 	background: '#fff',
						// },
					}),
				}),
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					lineHeight: '1em',
					fontSize: '.875rem',
					top: '-3px',
				}),
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: ({ theme }) => ({
					borderBottom: 'none',
				}),
			},
		},
		MuiSwitch: {
			styleOverrides: {
				root: ({ theme }) => ({
					'& .MuiSwitch-switchBase.Mui-checked': {
						color: theme.palette.custom.common.purple.primary,
						'&:hover': {
							backgroundColor: alpha(
								theme.palette.custom.common.purple.primary,
								theme.palette.action.hoverOpacity
							),
						},
					},
					'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
						backgroundColor: theme.palette.custom.common.purple.primary,
					},
				}),
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: ({ theme }) => ({
					width: '100%',
				}),
			},
		},
	},
};

let theme = createTheme(baseTheme);

theme = createTheme(theme, {
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					color: `${theme.palette.custom.common.darkGray}`,
					backgroundColor: theme.palette.custom.common.white,
					fontSize: '14px',
					borderRadius: '.5em',
					'&.Mui-error>fieldset': {
						border: '2px solid red',
					},
					'& > .MuiInputBase-input': {
						padding: '10px',
					},
				},
				margin: 'dense',
			},
		},
	},
});

export default theme;
