import React, { FC, useContext, useState } from 'react';
import './App.css';
import { AppContext } from '../AppContextProvider/context';

import { useInit } from './hooks';
import AppRoutes from './Routing/AppRoutes';

const App: FC = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { user } = useContext(AppContext);
	useInit(setIsLoading);

	if (isLoading) return null;

	return <AppRoutes user={user} />;
};

export default App;
