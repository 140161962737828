import React, { FC, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';

import IOrder from 'models/Order';
import ICase from 'models/Case';

import ButtonSuccess from 'components/common/ButtonSuccess';
import ButtonDanger from 'components/common/ButtonDanger';
import ButtonAction from 'components/common/ButtonAction';

import SpecialInstructionsTooltip from '../SpecialInstructionsTooltip';

import Case from './Case';
import CaseCard from './CaseCard';
import CaseFilter from './CaseFilter';
import NoRecordsMessage from './NoRecordsMessage';

import { useCaseData, useCaseYearFilter } from './hooks';
import { useStyles } from './style';
import getCaseTitle from './util/getCaseTitle';

interface Props {
	order: IOrder;
	case: ICase | null;
	setSelectedCase: (thisCase: ICase) => void;
	onChange: (property: string, newValue: string | boolean | ICase[]) => void;
}

const Order: FC<Props> = ({
	order,
	case: selectedCase,
	setSelectedCase,
	onChange,
}) => {
	const {
		container,
		heading,
		bottomHeadingRow,
		orderInfo,
		caseList,
		includeOrderBtn,
		nextCaseBtn,
	} = useStyles();
	const caseListRef = useRef<Element>();

	useEffect(() => {
		if (caseListRef.current) caseListRef.current.scrollTop = 0;
		handleOrderChange(order);
	}, [order['Order ID']]);

	const {
		filter,
		handleChangeYears,
		handleChangeProperty,
		handleChangeUseFilter,
	} = useCaseYearFilter();

	const {
		cases,
		displayedCases,
		handleUpdateCase,
		toggleCompleteOrder,
		handleOrderChange,
	} = useCaseData(order.cases, filter, onChange);

	const {
		'Order ID': orderId,
		'First Name': firstName,
		'Last Name': lastName,
		'Middle Name': middleName,
		'Special Instructions': specialInstructions,
		DOB,
	} = order;

	const handleToggleComplete = () => toggleCompleteOrder(order);
	const getCaseIndex = (array: ICase[]) =>
		selectedCase
			? array.findIndex((c) => c.key === selectedCase.key)
			: -1;
	const caseIndex = getCaseIndex(cases);
	const displayedCaseIndex = getCaseIndex(displayedCases);

	const handleOpenNextCase = () => {
		if (displayedCaseIndex === displayedCases.length - 1)
			setSelectedCase(displayedCases[0]);
		else setSelectedCase(displayedCases[displayedCaseIndex + 1]);
	};

	const handleOpenPreviousCase = () => {
		if (displayedCaseIndex === 0)
			setSelectedCase(displayedCases[displayedCases.length - 1]);
		else setSelectedCase(displayedCases[displayedCaseIndex - 1]);
	};

	return (
		<Box className={container}>
			<Box className={heading}>
				<Box className={orderInfo}>
					{orderId && (
						<p>
							Order ID: <strong>{orderId}</strong>
						</p>
					)}
					<p>
						DOB: <strong>{DOB}</strong>
					</p>
					<SpecialInstructionsTooltip text={specialInstructions} />
					<strong>
						{displayedCases.length} case
						{cases.length === 1 ? '' : 's'}
						{displayedCases.length !== cases.length &&
							` (${cases.length - displayedCases.length} hidden)`}
					</strong>
				</Box>
				<Box className={bottomHeadingRow}>
					<Typography variant='h4'>
						{`${lastName}, ${firstName} ${middleName}`}
						{!order.isCompleted && (
							<ButtonSuccess
								className={includeOrderBtn}
								onClick={handleToggleComplete}
							>
								Include Order
							</ButtonSuccess>
						)}
						{order.isCompleted && (
							<ButtonDanger
								className={includeOrderBtn}
								onClick={handleToggleComplete}
							>
								Exclude Order
							</ButtonDanger>
						)}
						{!selectedCase && (
							<CaseFilter
								filter={filter}
								handleChangeYears={handleChangeYears}
								handleChangeProperty={handleChangeProperty}
								handleChangeUseFilter={handleChangeUseFilter}
							/>
						)}
					</Typography>
					{selectedCase && cases.length >= 2 && (
						<Box>
							<ButtonAction onClick={handleOpenPreviousCase}>
								Previous Case
							</ButtonAction>
							<ButtonAction
								className={nextCaseBtn}
								onClick={handleOpenNextCase}
							>
								Next Case
							</ButtonAction>
						</Box>
					)}
				</Box>
			</Box>
			<Box></Box>
			{!selectedCase && cases.length > 0 && (
				<Box className={caseList}>
					{displayedCases.map((thisCase: ICase, i: number) => (
						<CaseCard
							case={thisCase}
							onClick={() => setSelectedCase(thisCase)}
							key={thisCase.key}
						/>
					))}
				</Box>
			)}
			{selectedCase && (
				<Case
					case={selectedCase!}
					caseNum={caseIndex + 1}
					title={getCaseTitle(selectedCase, displayedCaseIndex)}
					onSubmit={handleUpdateCase(caseIndex)}
				/>
			)}
			{cases.length === 0 && <NoRecordsMessage />}
		</Box>
	);
};

export default Order;
