import { useContext } from 'react';
import { SnackBarContextActions, SnackBarContext } from './context';

export const useSnackBar = (): SnackBarContextActions => {
	const context = useContext(SnackBarContext);

	if (!context) {
		throw new Error('useSnackBar must be used within an SnackBarProvider');
	}

	return context;
};