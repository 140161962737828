import React, { FC, useState, MouseEvent, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SendIcon from '@mui/icons-material/Send';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { useNavigate, useLocation } from 'react-router-dom';

import HeaderLogo from 'assets/headerLogo.svg';
import { Repo } from 'network/Repo';
import { AppContext } from 'components/AppContextProvider/context';
import { Roles } from 'constants/roles';
import { GeneralPermissions } from 'constants/permissions';

import { useStyles } from './style';
import isSuperAdmin from 'util/isSuperAdmin';

interface Params {
	displayExportIcon?: boolean;
	onExport?: () => void;
	onStartReview?: null | (() => void);
}

const HeaderBar: FC<Params> = ({
	displayExportIcon = false,
	onExport,
	onStartReview,
}) => {
	const { user } = useContext(AppContext);
	const navigate = useNavigate();
	const location: any = useLocation();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { container, headerLogo, icon, reviewIcon } = useStyles();

	const handleMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpenPage = (pathname: string) => () => navigate(pathname);
	const handleLogout = () => Repo.logout();

	return (
		<AppBar position='static'>
			<Toolbar className={container}>
				<IconButton
					size='large'
					edge='start'
					color='inherit'
					aria-label='menu'
					sx={{ mr: 2 }}
				>
					<img
						src={HeaderLogo}
						className={headerLogo}
						alt='PreDiscover by VersaDev Logo'
					/>
				</IconButton>

				<div>
					{displayExportIcon && (
						<Tooltip title={<p>Export</p>}>
							<IconButton
								size='large'
								aria-label='export orders'
								aria-controls='menu-appbar'
								aria-haspopup='false'
								onClick={onExport}
								color='inherit'
							>
								<SaveAltIcon className={icon} />
							</IconButton>
						</Tooltip>
					)}
					{onStartReview && (
						<Tooltip title={<p>Review & Send</p>}>
							<IconButton
								size='large'
								aria-label='start review'
								aria-controls='menu-appbar'
								aria-haspopup='false'
								onClick={onStartReview}
								color='inherit'
							>
								<SendIcon className={clsx(icon, reviewIcon)} />
							</IconButton>
						</Tooltip>
					)}
					{user &&
						user.roles.includes(Roles.RESEARCHER) &&
						user.company.permissions.includes(
							GeneralPermissions.ORDER_SUBMISSION_APP
						) &&
						!location.pathname.endsWith('/') && (
							<Tooltip title={<p>Orders</p>}>
								<IconButton
									size='large'
									aria-label='orders page'
									aria-controls='menu-appbar'
									aria-haspopup='false'
									onClick={handleOpenPage('/')}
									color='inherit'
								>
									<FormatListBulletedIcon className={icon} />
								</IconButton>
							</Tooltip>
						)}
					{user && (
						<>
							<Tooltip title={<p>Account</p>}>
								<IconButton
									size='large'
									aria-label='account of current user'
									aria-controls='menu-appbar'
									aria-haspopup='true'
									onClick={handleMenu}
									color='inherit'
								>
									<AccountCircle className={icon} />
								</IconButton>
							</Tooltip>
							<Menu
								id='menu-appbar'
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem
									onClick={handleOpenPage(
										'/settings/account'
									)}
								>
									Account
								</MenuItem>
								{user &&
									user.roles.includes(
										Roles.COMPANY_ADMIN
									) && (
										<MenuItem
											onClick={handleOpenPage(
												'/settings/company'
											)}
										>
											Company
										</MenuItem>
									)}
								{user && isSuperAdmin(user) && (
									<MenuItem
										onClick={handleOpenPage(
											'/settings/companies'
										)}
									>
										Companies
									</MenuItem>
								)}
								{user && isSuperAdmin(user) && (
									<MenuItem
										onClick={handleOpenPage(
											'/settings/first-name-rules'
										)}
									>
										First Name Rules
									</MenuItem>
								)}
								<MenuItem onClick={handleLogout}>
									Logout
								</MenuItem>
							</Menu>
						</>
					)}
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default HeaderBar;
