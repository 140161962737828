import { useCallback, useState } from 'react';

import IOrder from 'models/Order';
import ICase from 'models/Case';

export const useOrders = (setSelectedCase: (thisCase: ICase | null) => void) => {
	const [orders, setOrders] = useState<IOrder[]>([]);
	const [selectedOrderId, setSelectedOrderId] = useState<string>('');

	const handleUpdateOrderById = (orderId: string) => (
		property: string,
		newValue: string | boolean | ICase[]
	) => {
		setOrders((existingOrders) =>
			existingOrders.map((existingOrder) =>
				existingOrder['Order ID'] === orderId
					? { ...existingOrder, [property]: newValue }
					: existingOrder
			)
		);
	};

	const handleUpdateSelectedOrder = useCallback(
		(property: string, newValue: string | boolean | ICase[]) =>
			handleUpdateOrderById(selectedOrderId)(property, newValue),
		[selectedOrderId]
	);

	const handleOpenOrder = (orderId: string) => {
		setSelectedOrderId(orderId);
		setSelectedCase(null);
	}

	return {
		orders,
		selectedOrder: orders.find(
			(order) => order['Order ID'] === selectedOrderId
		),
		setOrders,
		handleOpenOrder,
		handleUpdateOrderById,
		handleUpdateSelectedOrder,
	};
};
