const prefixDOBWithZero = (dob: string) => {
	if (
		parseInt(dob.split('/')[0]) < 10 &&
		!dob.startsWith('0')
	) {
		dob = `0${dob}`;
	}

	return dob;
};

export default prefixDOBWithZero;