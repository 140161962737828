import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexRowStartCenter, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	tabs: {
		...fullWidth,
		...flexRowStartCenter,
		paddingTop: '8px',
	},
	tab: {
		margin: '0 16px 0 0',
		cursor: 'pointer',
	},
	tab_selected: {
		color: theme.palette.custom.common.purple.primary,
		borderBottom: `2px solid ${theme.palette.custom.common.purple.primary}`,
		fontWeight: 700
	}
}))