import { Dispatch, SetStateAction } from 'react';

export interface InputValidationMap {
	value: any;
	setErrorFunc: Dispatch<SetStateAction<boolean>>;
}

export const containsInputErrors = (maps: InputValidationMap[], stopOnError: boolean = false): boolean => {
	let containsErrors = false;

	if(stopOnError){
		maps.some((map: InputValidationMap) => {
			if (!map.value) {
				containsErrors = true;
				map.setErrorFunc(true);
				return true;
			}
		});
	} else {
		maps.forEach((map: InputValidationMap) => {
			if (!map.value) {
				containsErrors = true;
				map.setErrorFunc(true);
			}
		});
	}

	return containsErrors;
};

export const createValidationMap = (
	value: any,
	setErrorFunc: Dispatch<SetStateAction<boolean>>
): InputValidationMap => ({ value, setErrorFunc });
