import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { flexColCenterCenter, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullHeight,
		...fullWidth,
		...flexColCenterCenter,
	},
	uploadIcon: {
		width: '128px !important',
		height: '128px !important',
		color: theme.palette.custom.common.lightGray
	},
	uploadBtn: {
		padding: `0 !important`,

		'& > label': {
			padding: '5px 15px',
			cursor: 'pointer',
		},

		'& input[type="file"]': {
			display: 'none',
		}
	}
}));