import { lighten, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexRowCenterCenter, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullHeight,
		width: '240px',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		boxShadow: `2px 2px 5px ${theme.palette.custom.common.lightGray}`
	},
	orderList: {
		...fullWidth,
		height: 'calc(100% - 35px)',
		// padding: '8px',
		overflowY: 'auto',
		direction: 'rtl',
		backgroundColor: theme.palette.custom.common.white,
		borderRight: `2px solid ${theme.palette.custom.common.lightGray}`,

		'&::-webkit-scrollbar': {
			width: '12px',
		},

		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': `inset 0 0 6px ${theme.palette.custom.common.lightGray}`,
			borderRadius: '10px',
		},

		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.custom.common.lightGray,
			borderRadius: '10px',
			'-webkit-box-shadow': `inset 0 0 6px ${theme.palette.custom.common.darkGray}`,
		},
		
		'& > div:nth-of-type(1)': {
			marginTop: 0,
		}
	},
	switcher: {
		...fullWidth,
		...flexRowCenterCenter,
		height: '35px',
		borderRight: `2px solid ${theme.palette.custom.common.lightGray}`,
		// borderBottom: `2px solid ${theme.palette.custom.common.white}`,
	},
	switcherOption: {
		...flexRowCenterCenter,
		width: '50%',
		height: '100%',
		textAlign: 'center',
		color: theme.palette.custom.common.black,
		backgroundColor: lighten(theme.palette.custom.common.lightGray, .6),
		borderBottom: `2px solid ${theme.palette.custom.common.black}`,
		// borderTop: `2px solid ${theme.palette.custom.common.lightGray}`,
		cursor: 'pointer',

		'& > h4': {
			margin: 0,
		},
	},
	selectedSwitcherOption: {
		// borderTop: `2px solid ${theme.palette.custom.common.purple.primary}`,
		// borderTop: 'none',
		background: theme.palette.custom.common.purple.light,
		color: theme.palette.custom.common.white,
	},
}));
