import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexCol, flexRowStartEnd, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...flexCol,
		flex: 1,
		paddingTop: 0,

		
	},
	heading: {
		...flexRowStartEnd,
		backgroundColor: theme.palette.custom.common.black,
		color: theme.palette.custom.common.white,
		padding: '8px',
		borderBottom: `1px solid ${theme.palette.custom.common.lightGray}`,
		borderTopLeftRadius: '5px',
		borderTopRightRadius: '5px',

		'& > h3': {
			margin: 0,
		},
	},
	contentContainer: {
		padding: '8px',

		'& > div': {
			marginBottom: '8px',
		}
	},
}))