import { Theme, lighten } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexCol, flexRowSpaceBetweenCenter, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullHeight,
		...flexCol,
		flex: 1,
		backgroundColor: lighten(theme.palette.custom.common.lightGray, .3)
	},
	content: {
		...fullWidth,
		...flexCol,
		flex: 1,
		overflowY: 'auto',
		backgroundColor: lighten(theme.palette.custom.common.lightGray, .6),
	},
	btnContainer: {
		...flexRowSpaceBetweenCenter,
		width: 'calc(100% - 16px)',
		marginLeft: '8px',
		position: 'fixed',
		top: '70px',
		backgroundColor: 'transparent',
	},
	backBtn: {
		width: '200px',
	}
}))