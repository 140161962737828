import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexCol, flexRowEnd } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	dialogContent: {
		...flexCol,
		width: '100%',
		maxHeight: 'calc(100vh - 216px)',
		padding: '16px !important',
		overflowY: 'auto',
	},
	buttonContainer: {
		...flexRowEnd,
		height: 'auto',
		padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
		borderTop: '1px solid #E0E0E0',
	},
}));
