import React, { FC } from 'react';
import { Box, Paper, Table, TableBody, TableContainer } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ICase from 'models/Case';

import { useStyles } from './style';
import StyledTableCell from 'components/common/StyledTableCell';
import StyledTableRow from 'components/common/StyledTableRow';
import ChargeReview from './ChargeReview';

interface Props {
	case: ICase;
	caseIndex: number;
}

const CaseReview: FC<Props> = ({ case: thisCase, caseIndex }) => {
	const { container, heading, contentContainer } = useStyles();
	const {
		'Case #': caseNum,
		'First Name': firstName,
		'Middle Name': middleName,
		'Last Name': lastName,
		DOB: dob,
		SSN: ssn,
		DL: driversLicense,
		Sex: gender,
		Race: ethnicity,
		Address: address,
		'Offense Date': offenseDate,
		'Arrest Date': arrestDate,
		'Disposition Date': dispositionDate,
		'Filing Date': filingDate,
		'Type of Court': courtType,
		charges,
	} = thisCase;

	return (
		<Box className={container} component={Paper}>
			<Box className={heading}>
				<h3>
					Case {caseIndex + 1} - {caseNum}
				</h3>
			</Box>
			<Box className={contentContainer}>
				<TableContainer component={Paper}>
					<Table size='small' aria-label='customized table'>
						<TableHead>
							<TableRow>
								<StyledTableCell align='left'>
									Defendant
								</StyledTableCell>
								<StyledTableCell align='left'>
									DOB
								</StyledTableCell>
								<StyledTableCell align='left'>
									SSN
								</StyledTableCell>
								<StyledTableCell align='left'>
									DL
								</StyledTableCell>
								<StyledTableCell align='left'>
									Gender
								</StyledTableCell>
								<StyledTableCell>Ethnicity</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<StyledTableRow>
								<StyledTableCell align='left'>
									{`${lastName}, ${firstName} ${middleName}`}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{dob}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{ssn}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{driversLicense}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{gender}
								</StyledTableCell>
								<StyledTableCell component='th' scope='row'>
									{ethnicity}
								</StyledTableCell>
							</StyledTableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<TableContainer component={Paper}>
					<Table size='small' aria-label='customized table'>
						<TableHead>
							<TableRow>
								<StyledTableCell align='left'>
									Address
								</StyledTableCell>
								<StyledTableCell align='left'>
									Offense Date
								</StyledTableCell>
								<StyledTableCell align='left'>
									Arrest Date
								</StyledTableCell>
								<StyledTableCell align='left'>
									Filing Date
								</StyledTableCell>
								<StyledTableCell align='left'>
									Disposition Date
								</StyledTableCell>
								<StyledTableCell align='left'>
									Court Type
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<StyledTableRow>
								<StyledTableCell align='left'>
									{address}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{offenseDate}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{arrestDate}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{filingDate}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{dispositionDate}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{courtType}
								</StyledTableCell>
							</StyledTableRow>
						</TableBody>
					</Table>
				</TableContainer>
				{charges.map((charge, i) => (
					<ChargeReview charge={charge} chargeIndex={i} key={charge.key} />
				))}
			</Box>
		</Box>
	);
};

export default CaseReview;
