import React, { FC, useContext } from 'react';
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import clsx from 'clsx';

import { Roles } from 'constants/roles';
import { ICompany } from 'models/Company';
import IUser from 'models/User';
import {
	fullWidth,
	flexRowSpaceBetweenCenter,
	flexRow,
} from 'styles/stylesLib';
import formatDate from 'util/formatDate';

import { AppContext } from 'components/AppContextProvider/context';
import ButtonAction from 'components/common/ButtonAction';
import ButtonDanger from 'components/common/ButtonDanger';
import ButtonSuccess from 'components/common/ButtonSuccess';
import CheckboxWithLabel from 'components/common/CheckboxWithLabel';
import StyledTableCell from 'components/common/StyledTableCell';
import StyledTableRow from 'components/common/StyledTableRow';
import TextFieldOutlined from 'components/common/TextFieldOutlined';

import { useSnackBar } from 'components/SnackBarContextProvider/hooks';

import { useLicenseHandlers, useLicenses, useNumOfAdmins } from './hooks';
import { useStyles } from './style';

interface Props {
	company: ICompany;
	users: IUser[];
	onSetCompany: (company: ICompany) => void;
}

const UserManagementSection: FC<Props> = ({ company, users, onSetCompany }) => {
	const {
		halfWidthCol,
		licenseListContainer,
		newLicenseContainer,
		newLicenseForm,
		roleCell,
	} = useStyles();
	const { handleSetUsers } = useContext(AppContext);
	const snackBar = useSnackBar();

	const {
		usersWithLicenses,
		inactiveLicenses,
		sentLicenses,
		availableLicenses,
	} = useLicenses({ company, users });

	const {
		handleInvite,
		handleRevokeInvite,
		handleResendInvite,
		handleLicenseActivation,
		handleChangeRole,
	} = useLicenseHandlers({
		companyId: company.id,
		snackBar,
		users,
		availableLicenses,
		handleSetUsers,
		handleSetCompany: onSetCompany
	});

	const numOfAdmins = useNumOfAdmins(users);

	return (
		<>
			<Box style={{ ...fullWidth, ...flexRowSpaceBetweenCenter }}>
				<h2>Users & Licenses</h2>
				<Box className={newLicenseContainer}>
					<form className={newLicenseForm} onSubmit={handleInvite}>
						<TextFieldOutlined name='email' label='Email' />
						<ButtonAction
							type='submit'
							disabled={!availableLicenses.length}
						>
							{availableLicenses.length > 0
								? `Send Invite (${availableLicenses.length})`
								: 'No licenses Available'}
						</ButtonAction>
					</form>
				</Box>
			</Box>
			<Box style={{ ...flexRow }}>
				<Box className={clsx(halfWidthCol, licenseListContainer)}>
					<h5>REGISTERED USERS ({usersWithLicenses.length})</h5>
					{usersWithLicenses.length > 0 && (
						<Table size='small' component={Paper}>
							<TableHead>
								<TableRow
									style={{
										borderBottom: '2px solid black',
									}}
								>
									<TableCell>Name</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Roles</TableCell>
									<TableCell>Activated on</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{usersWithLicenses.map(
									({ id, license, roles = [] }) => (
										<StyledTableRow key={license!.id}>
											<StyledTableCell>
												{license!.userName}
											</StyledTableCell>
											<StyledTableCell>
												{license!.email}
											</StyledTableCell>
											<StyledTableCell>
												<CheckboxWithLabel
													label='Admin'
													labelClassName={roleCell}
													checked={roles.includes(
														Roles.COMPANY_ADMIN
													)}
													onChange={handleChangeRole(
														company.id,
														id,
														roles,
														Roles.COMPANY_ADMIN
													)}
													disabled={
														numOfAdmins === 1 &&
														roles.includes(
															Roles.COMPANY_ADMIN
														)
													}
												/>
												<CheckboxWithLabel
													label='Researcher'
													labelClassName={roleCell}
													checked={roles.includes(
														Roles.RESEARCHER
													)}
													onChange={handleChangeRole(
														company.id,
														id,
														roles,
														Roles.RESEARCHER
													)}
												/>
											</StyledTableCell>
											<StyledTableCell>
												{formatDate(
													new Date(
														license!.statusChangedDateTimeUTC!
													)
												)}
											</StyledTableCell>
											<StyledTableCell>
												<ButtonDanger
													onClick={handleLicenseActivation(
														license!.id,
														false
													)}
												>
													Deactivate
												</ButtonDanger>
											</StyledTableCell>
										</StyledTableRow>
									)
								)}
							</TableBody>
						</Table>
					)}
					<h5>INACTIVE USERS ({inactiveLicenses.length})</h5>
					{inactiveLicenses.length > 0 && (
						<Table size='small' component={Paper}>
							<TableHead>
								<TableRow
									style={{
										borderBottom: '2px solid black',
									}}
								>
									<TableCell>Name</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Deactivated on</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{inactiveLicenses.map((license) => (
									<StyledTableRow key={license.id}>
										<StyledTableCell>
											{license.userName}
										</StyledTableCell>
										<StyledTableCell>
											{license.email}
										</StyledTableCell>
										<StyledTableCell>
											{formatDate(
												new Date(
													license.statusChangedDateTimeUTC!
												)
											)}
										</StyledTableCell>
										<StyledTableCell>
											<ButtonSuccess
												onClick={handleLicenseActivation(
													license.id,
													true
												)}
											>
												Reactivate
											</ButtonSuccess>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					)}
				</Box>
				<Box className={halfWidthCol}>
					<h5>PENDING INVITES ({sentLicenses.length})</h5>
					{sentLicenses.length > 0 && (
						<Table size='small' component={Paper}>
							<TableHead>
								<TableRow
									style={{
										borderBottom: '2px solid black',
									}}
								>
									<TableCell>Email</TableCell>
									<TableCell>Sent on</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{sentLicenses.map((license) => (
									<StyledTableRow key={license.id}>
										<StyledTableCell>
											{license.email}
										</StyledTableCell>
										<StyledTableCell>
											{formatDate(
												new Date(
													license.statusChangedDateTimeUTC!
												)
											)}
										</StyledTableCell>
										<StyledTableCell>
											<ButtonSuccess
												onClick={handleResendInvite(
													license.id
												)}
											>
												Resend Invite
											</ButtonSuccess>
										</StyledTableCell>
										<StyledTableCell>
											<ButtonDanger
												onClick={handleRevokeInvite(
													license.id
												)}
											>
												Revoke Invite
											</ButtonDanger>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					)}
				</Box>
			</Box>
		</>
	);
};

export default UserManagementSection;
