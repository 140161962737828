import { lighten, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexCol, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...fullHeight,
		...flexCol,
		overflow: 'hidden',
		backgroundColor: lighten(theme.palette.custom.common.lightGray, 0.6),
	},
	content: {
		...fullWidth,
		...flexCol,
		height: 'calc(100% - 64px)',
		padding: '16px',
		paddingBottom: 0,

		'& h1, h2': {
			margin: 0,
		},

		'& h2': {
			marginLeft: '16px',
		},
	},
	heading: {
		borderBottom: `2px solid ${theme.palette.custom.common.black}`,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		paddingBottom: '8px',
	},
}));
