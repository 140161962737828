import ICase from 'models/Case';

const byVerified = (defendantDOB: number, defendantSSN: string, defendantFirstName: string) => (a: Partial<ICase>, b: Partial<ICase>) => {
	if (a.DOB && !b.DOB) return -1;
	else if (!a.DOB && b.DOB) return 1;

	const aDate = new Date(a.DOB!).getTime();
	const bDate = new Date(b.DOB!).getTime();

	if (a.SSN === defendantSSN) return -1;
	else if (b.SSN === defendantSSN) return 1;
	else if (defendantDOB && aDate === defendantDOB) return -1;
	else if (defendantDOB && bDate === defendantDOB) return 1;
	else if (a.verifiedBy!.length > b.verifiedBy!.length) return -1;
	else if (b.verifiedBy!.length > a.verifiedBy!.length) return 1;
	else if (
		a.Defendant!.toUpperCase().includes(defendantFirstName.toUpperCase())
	)
		return -1;
	else if (
		b.Defendant!.toUpperCase().includes(defendantFirstName.toUpperCase())
	)
		return 1;
	else if (
		defendantDOB &&
		Math.abs(defendantDOB - bDate) < Math.abs(defendantDOB - aDate)
	)
		return 1;

	return -1;
};

export default byVerified;