import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexCol, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...flexCol,
		flex: 1,
		padding: '8px',
		marginTop: '26px',
	},
}))