import ISentence from 'models/Sentence';
import ICase from 'models/Case';
import ICharge from 'models/Charge';
import IEvent from 'models/Event';
import { useEffect, useState } from 'react';

const arraysAreEqual = (arr1: any[], arr2: any[]) =>
	JSON.stringify(arr1) === JSON.stringify(arr2);

const shouldAddVerifiedByToChangedFields = (
	property: string,
	changedFields: string[],
	thisCase: ICase,
	newValue: any
) =>
	property === 'verifiedBy' &&
	!changedFields.includes(property) &&
	!arraysAreEqual(newValue as string[], thisCase[property]);

const shouldRemoveVerifiedByToChangedFields = (
	property: string,
	changedFields: string[],
	thisCase: ICase,
	newValue: any
) =>
	property === 'verifiedBy' &&
	changedFields.includes(property) &&
	arraysAreEqual(newValue as string[], thisCase[property]);

export const useChargesData = (
	thisCase: ICase,
	onSubmit: (newCase: ICase) => void
) => {
	const [tempCase, setTempCase] = useState(thisCase);
	const [changedFields, setChangedFields] = useState<string[]>([]);

	useEffect(() => {
		setTempCase(thisCase);
		setChangedFields([]);
	}, [thisCase]);

	const handleChange = (property: string) => (
		newValue: string | string[] | boolean | ICharge[] | IEvent[]
	) => {
		if (
			(newValue !== thisCase[property] &&
				!changedFields.includes(property)) ||
			shouldAddVerifiedByToChangedFields(
				property,
				changedFields,
				thisCase,
				newValue
			)
		) {
			setChangedFields((existingFields) =>
				existingFields.concat(property)
			);
		} else if (
			(newValue === thisCase[property] &&
				changedFields.includes(property)) ||
			shouldRemoveVerifiedByToChangedFields(
				property,
				changedFields,
				thisCase,
				newValue
			)
		) {
			setChangedFields((existingFields) =>
				existingFields.filter((field) => field !== property)
			);
		}

		setTempCase((existingCase) => ({
			...existingCase,
			[property]: newValue,
		}));
	};

	const handleVerifiedByCheckboxChange = (property: string) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const newList = tempCase.verifiedBy?.includes(property)
			? tempCase.verifiedBy.filter((field) => field !== property)
			: tempCase.verifiedBy.concat(property);

		handleChange('verifiedBy')(newList);
	};

	const handleSwitchChange = (property: string) => (
		e: React.ChangeEvent<HTMLInputElement>,
		newValue: boolean
	) => handleChange(property)(newValue);

	const handleInputChange = (property: string) => (
		e: React.ChangeEvent<HTMLInputElement>, value?: any
	) => handleChange(property)(value || e.target.value);

	const handleTextAreaChange = (property: string) => (
		e: React.ChangeEvent<HTMLTextAreaElement>
	) => handleChange(property)(e.target.value);

	const handleChangeCharge = (index: number) => (property: string) => (
		newValue: string | boolean | ISentence[]
	) =>
		handleChange('charges')(
			tempCase.charges.map((charge, i) =>
				i === index ? { ...charge, [property]: newValue } : charge
			)
		);

	const handleSaveChanges = () => {
		setChangedFields([]);
		onSubmit(tempCase);
	};

	const handleChangeDOB = (datePart: 'Day' | 'Month' | 'Year') => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = parseInt(e.target.value) || '';

		const hasDatePartOrDatePartIsChanging = (
			dp: 'Day' | 'Month' | 'Year'
		) => tempCase[`DOB ${dp}`] || datePart === dp;

		handleInputChange(`DOB ${datePart}`)(e);
		if (
			value &&
			hasDatePartOrDatePartIsChanging('Day') &&
			hasDatePartOrDatePartIsChanging('Month') &&
			hasDatePartOrDatePartIsChanging('Year')
		) {
			const dob = new Date(
				parseInt(tempCase['DOB Year']) ?? 1,
				(parseInt(tempCase['DOB Month']) || 1) - 1,
				parseInt(tempCase['DOB Day']) ?? 1
			);
			switch (datePart) {
				case 'Day':
					dob.setDate(value);
					break;
				case 'Month':
					dob.setMonth(value - 1);
					break;
				case 'Year':
					dob.setFullYear(value);
					break;
				default:
					break;
			}

			const dobMonth = dob.getMonth() + 1;
			const dobDate = dob.getDate();
			handleInputChange('DOB')(
				e,
				`${dobMonth < 10 ? '0' : ''}${dobMonth}/${dobDate > 9 ? dobDate : `0${dobDate}`}/${dob.getFullYear()}`
			);
		} else handleInputChange('DOB')(e, '');
	};

	return {
		tempCase,
		hasChanges: changedFields.length > 0,
		handleChange,
		handleInputChange,
		handleTextAreaChange,
		handleSwitchChange,
		handleVerifiedByCheckboxChange,
		handleChangeCharge,
		handleChangeDOB,
		handleSaveChanges,
	};
};
