export const colors = {
	black: '#111',
	white: '#FFFFFF',
	lightGray: '#D0CDD7',
	darkGray: '#282c34',
	orange: '#DE7C5A',
	green: '#28a745',
	purple: '#7F00FF',
	pink: '#E100FF',
	red: '#dc3545',
}