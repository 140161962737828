import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {
	Table,
	Paper,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';

import { AppContext } from 'components/AppContextProvider/context';
import SettingsPage from 'components/common/SettingsPage';
import StyledTableCell from 'components/common/StyledTableCell';
import StyledTableRow from 'components/common/StyledTableRow';
import ButtonAction from 'components/common/ButtonAction';

import { ICompany, IUserLicense, LicenseStatuses } from 'models/Company';
import { Repo } from 'network/Repo';
import isSuperAdmin from 'util/isSuperAdmin';
import formatDate from 'util/formatDate';

const CompaniesPage = () => {
	const navigate = useNavigate();
	const { user, companies, handleSetCompanies } = useContext(AppContext);
	if (!isSuperAdmin(user!)) {
		navigate('/');
	}

	useEffect(() => {
		Repo.getCompanies(handleSetCompanies);
	}, []);

	const handleEditCompany = (companyId: string) => () => {
		navigate(`/settings/companies/${companyId}`);
	};

	const handleNewCompanyBtnClick = () => navigate('/settings/companies/new');
	const actions = [
		<ButtonAction onClick={handleNewCompanyBtnClick} key='new-company-btn'>
			<AddIcon />
			New Company
		</ButtonAction>,
	];

	return (
		<SettingsPage title='Companies' actions={actions}>
			<Table size='small' component={Paper} style={{ marginTop: '16px' }}>
				<TableHead>
					<TableRow
						style={{
							borderBottom: '2px solid black',
						}}
					>
						<TableCell>Name</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Total Licenses</TableCell>
						<TableCell>Active Users</TableCell>
						<TableCell>Pending Invites</TableCell>
						<TableCell>Unused Licenses</TableCell>
						<TableCell>Created on</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{companies.map((company: ICompany) => (
						<StyledTableRow key={company.id}>
							<StyledTableCell>{company.name}</StyledTableCell>
							<StyledTableCell>{company.email}</StyledTableCell>
							<StyledTableCell>
								{company.licenses?.filter(
									(license: IUserLicense) =>
										license.status !==
										LicenseStatuses.INACTIVE
								).length ?? 0}
							</StyledTableCell>
							<StyledTableCell>
								{company.licenses?.filter(
									(license: IUserLicense) =>
										license.status ===
										LicenseStatuses.IN_USE
								).length ?? 0}
							</StyledTableCell>
							<StyledTableCell>
								{company.licenses?.filter(
									(license: IUserLicense) =>
										license.status ===
										LicenseStatuses.INVITE_SENT
								).length ?? 0}
							</StyledTableCell>
							<StyledTableCell>
								{company.licenses?.filter(
									(license: IUserLicense) =>
										license.status ===
										LicenseStatuses.AVAILABLE
								).length ?? 0}
							</StyledTableCell>
							<StyledTableCell>
								{formatDate(
									new Date(company.createDateTimeUTC)
								)}
							</StyledTableCell>
							<StyledTableCell>
								<ButtonAction
									onClick={handleEditCompany(company.id)}
								>
									Edit
								</ButtonAction>
							</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</SettingsPage>
	);
};

export default CompaniesPage;
