import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Repo } from 'network/Repo';
import isSuperAdmin from 'util/isSuperAdmin';
import IFirstNameRule from 'models/FirstNameRule';

import { AppContext } from 'components/AppContextProvider/context';
import SettingsPage from 'components/common/SettingsPage';
import CheckboxWithLabel from 'components/common/CheckboxWithLabel';

import { useStyles } from './style';
import RuleActionOption from './RuleActionOption';

const FirstNameRuleEditPage = () => {
	const navigate = useNavigate();
	const { user, firstNameRules, handleSetFirstNameRules } = useContext(
		AppContext
	);

	if (!isSuperAdmin(user!)) navigate('/');

	const { content, column, namesList } = useStyles();
	const { ruleName } = useParams();
	const [applyOppositeRule, setApplyOppositeRule] = useState(true);

	useEffect(() => {
		if (!firstNameRules.length)
			Repo.getAllFirstNameRules(handleSetFirstNameRules);
	}, []);

	const rule = firstNameRules.find(
		(rule: IFirstNameRule) => rule.name === ruleName
	);
	const handleSetRule = (newRule: IFirstNameRule, isOppositeRule?: boolean) => {
		if(!isOppositeRule){
			handleSetFirstNameRules(
				firstNameRules.map((r) => (r.name === ruleName ? newRule : r))
			);
		}
	}
		
	return (
		<SettingsPage title={`First Name Rule: ${rule?.name ?? ''}`}>
			{rule && (
				<Box className={content}>
					<Box className={column}>
							<h3 style={{ marginBottom: '8px'}}>Suggestions ({rule.suggestedNames.length})</h3>
							<Box style={{ width: '240px' }}>
							<CheckboxWithLabel
								label='Apply opposite rule'
								checked={applyOppositeRule}
								onChange={() => setApplyOppositeRule(!applyOppositeRule)}
							/>
							</Box>
						<Box className={namesList}>
						{rule.suggestedNames.sort().map((name) => (
							<RuleActionOption
								ruleName={rule.name}
								name={name}
								list='suggestedNames'
								onSetRule={handleSetRule}
								applyOppositeRule={applyOppositeRule}
								key={name}
							/>
						))}
						</Box>
					</Box>
					<Box className={column}>
						<h3>Accepted ({rule.acceptedNames.length})</h3>
						<Box className={namesList}>
						{rule.acceptedNames.sort().map((name) => (
							<RuleActionOption
								ruleName={rule.name}
								name={name}
								list='acceptedNames'
								onSetRule={handleSetRule}
								key={name}
							/>
						))}
						</Box>
					</Box>
					<Box className={column}>
						<h3>Excluded ({rule.excludedNames.length})</h3>
						<Box className={namesList}>
						{rule.excludedNames.sort().map((name) => (
							<RuleActionOption
								ruleName={rule.name}
								name={name}
								list='excludedNames'
								onSetRule={handleSetRule}
								key={name}
							/>
						))}
						</Box>
					</Box>
				</Box>
			)}
		</SettingsPage>
	);
};

export default FirstNameRuleEditPage;
