import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';

import IOrder from 'models/Order';

import HeaderBar from '../../HeaderBar';
import OrderSelector from './OrderSelector';
import UploadFileMessage from './UploadFileMessage';
import Order from './Order';
import Review from './Review';
import ICase from 'models/Case';

import { useStyles } from './style';
import { useOrders } from './hooks';
import ExportModal from './ExportModal';

const HomePage: FC = () => {
	const { container, content } = useStyles();
	const [isReviewMode, setIsReviewMode] = useState(false);
	const [isExportModalOpen, setIsExportModalOpen] = useState(false);
	const [selectedCase, setSelectedCase] = useState<ICase | null>(null);
	const countyRef = useRef('');
	const {
		orders,
		selectedOrder,
		setOrders,
		handleOpenOrder,
		handleUpdateSelectedOrder,
		handleUpdateOrderById,
	} = useOrders(setSelectedCase);

	useEffect(() => setSelectedCase(null), [isReviewMode]);

	const ordersForReview = useMemo(
		() =>
			(isReviewMode
				? orders.filter((order) => order.isCompleted)
				: []
			).map((order) => {
				const orderObj = {
					...order,
					cases: order.cases
						.filter((thisCase) => thisCase.isIncluded)
						.map((thisCase) => {
							let caseObj = {
								...thisCase,
								charges: thisCase.charges.filter(
									(charge) => charge.isIncluded
								),
							};

							delete caseObj.events;
							return caseObj;
						}),
				};

				orderObj.noRecordsFound = orderObj.cases.length === 0;
				return orderObj;
			}),
		[isReviewMode]
	);

	const handleReceiveOrders = (newOrders: IOrder[]) => {
		setOrders(newOrders);
		countyRef.current = newOrders[0].County;
	};
	const handleToggleReview = () => setIsReviewMode(!isReviewMode);
	const handleToggleExportModal = () =>
		setIsExportModalOpen((isOpen) => !isOpen);

	return (
		<Box className={container}>
			<HeaderBar
				displayExportIcon={orders.length > 0}
				onExport={handleToggleExportModal}
				onStartReview={
					orders.length && !isReviewMode ? handleToggleReview : null
				}
			/>
			<Box className={content}>
				{orders.length === 0 ? (
					<UploadFileMessage onUpload={handleReceiveOrders} />
				) : ''}
				{isReviewMode && (
					<Review
						orders={ordersForReview}
						setOrders={setOrders}
						onCancelReview={handleToggleReview}
					/>
				)}
				{(orders.length > 0 && !isReviewMode) ? (
					<OrderSelector
						orders={orders}
						setSelectedOrderId={handleOpenOrder}
						onChange={handleUpdateOrderById}
					/>
				) : ''}
				{selectedOrder && !isReviewMode && (
					<Order
						order={selectedOrder}
						case={selectedCase}
						setSelectedCase={setSelectedCase}
						onChange={handleUpdateSelectedOrder}
					/>
				)}
			</Box>
			{orders.length > 0 ? (
				<ExportModal
					isOpen={isExportModalOpen}
					county={countyRef.current}
					orders={orders}
					onClose={handleToggleExportModal}
				/>
			) : ''}
		</Box>
	);
};

export default HomePage;
