import React, { FC, useEffect, useRef, useState } from 'react';
import {
	OutlinedInput,
	OutlinedInputProps,
} from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';

const IOSSwitch: FC<{ label: string } & OutlinedInputProps> = ({
	label,
	value,
	...rest
}) => {
	const { labelRoot, labelSmall, textarea } = useStyles();
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		if (textAreaRef.current !== null) {
			textAreaRef.current.style.height =
				textAreaRef.current.scrollHeight - 24 + 'px';
		}
	}, []);

	const handleToggleFocus = () => setIsFocused(!isFocused);

	return (
		<OutlinedInput
			className={textarea}
			label={label}
			value={value}
			inputComponent='textarea'
			inputProps={{ ref: textAreaRef }}
			classes={{
				notchedOutline: clsx(labelRoot, {[labelSmall]: value || isFocused })
			}}
			onFocus={handleToggleFocus}
			onBlur={handleToggleFocus}
			{...rest}
		/>
	);
};

export default IOSSwitch;
