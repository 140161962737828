import React, { FC } from 'react';
import { Box, Paper, Table, TableBody, TableContainer } from '@mui/material';

import ICharge from 'models/Charge';

import { useStyles } from './style';
import StyledTableCell from 'components/common/StyledTableCell';
import StyledTableRow from 'components/common/StyledTableRow';

interface Props {
	charge: ICharge;
	chargeIndex: number;
}

const ChargeReview: FC<Props> = ({ charge, chargeIndex }) => {
	const { container, heading, contentContainer, content } = useStyles();
	const {
		Offense: offense,
		Disposition: disposition,
		'Offense Level': offenseLevel,
		'Next Court Date': nextCourtDate,
		Probation: probation,
		'Community Service': communityService,
		Fine: fine,
		'Court Fee': courtFee,
		Restitution: restitution,
		'Prison Time': prisonTime,
		'Prison Credit Time': prisonCreditTime,
		'Prison Time Suspended': prisonTimeSuspended,
		'Jail Time': jailTime,
		'Jail Credit Time': jailCreditTime,
		'Jail Time Suspended': jailTimeSuspended,
		'Other Sentencing Details': otherSentencingDetails,
	} = charge;

	return (
		<Box className={container}>
			<Box className={heading}>
				<h4>Charge {chargeIndex + 1}</h4>
			</Box>
			<Box className={contentContainer}>
				<TableContainer component={Paper}>
					<Table size='small'>
						<TableBody>
							<StyledTableRow>
								<StyledTableCell
									component='td'
									scope='row'
									width={60}
								>
									<strong>Charge:</strong>
								</StyledTableCell>
								<StyledTableCell component='td' scope='row'>
									{offense}
								</StyledTableCell>
							</StyledTableRow>
							<StyledTableRow>
								<StyledTableCell
									component='td'
									scope='row'
									width={220}
								>
									<strong>Other Sentencing Details:</strong>
								</StyledTableCell>
								<StyledTableCell component='td' scope='row'>
									{otherSentencingDetails}
								</StyledTableCell>
							</StyledTableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<Box className={content}>
					<TableContainer component={Paper}>
						<Table size='small'>
							<TableBody>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Disposition:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{disposition}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Offense Level:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{offenseLevel}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Next Court Date:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{nextCourtDate}
									</StyledTableCell>
								</StyledTableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<TableContainer component={Paper}>
						<Table size='small'>
							<TableBody>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Probation:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{probation}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Community Service:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{communityService}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Court Fee:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{courtFee}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Restitution:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{restitution}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Fine:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{fine}
									</StyledTableCell>
								</StyledTableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<TableContainer component={Paper}>
						<Table size='small'>
							<TableBody>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Jail Time:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{jailTime}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Jail Credit Time:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{jailCreditTime}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Jail Time Suspended:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{jailTimeSuspended}
									</StyledTableCell>
								</StyledTableRow>
							</TableBody>
						</Table>
					</TableContainer>
					<TableContainer component={Paper}>
						<Table size='small'>
							<TableBody>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Prison Time:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{prisonTime}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Prison Credit Time:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{prisonCreditTime}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell
										component='td'
										scope='row'
										width={150}
									>
										<strong>Prison Time Suspended:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{prisonTimeSuspended}
									</StyledTableCell>
								</StyledTableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</Box>
	);
};

export default ChargeReview;
