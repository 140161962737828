import { useMemo, useState } from 'react';

import IUser from 'models/User';
import { ICompany } from 'models/Company';
import IFirstNameRule from 'models/FirstNameRule';

export const useAppContextValue = () => {
	const [user, setUser] = useState<IUser | null>(null);
	const [users, setUsers] = useState<{ [key: string]: IUser[] }>({});
	const [companies, setCompanies] = useState<ICompany[]>([]);
	const [firstNameRules, setFirstNameRules] = useState<IFirstNameRule[]>([]);

	return useMemo(() => {
		const handleSetCompany = (company: ICompany) => {
			setUser((existingUser: IUser | null) => ({
				...existingUser!,
				company,
			}));
		};

		const handleSetUsers = (companyId: string, incomingUsers: IUser[]) => {
			const newUsers = { ...users, ...{ [companyId]: incomingUsers } };
			setUsers(newUsers);
		};

		return {
			user,
			users,
			companies,
			firstNameRules,
			handleSetUser: setUser,
			handleSetUsers: handleSetUsers,
			handleSetCompany,
			handleSetCompanies: setCompanies,
			handleSetFirstNameRules: setFirstNameRules,
		};
	}, [
		user,
		users,
		companies,
		firstNameRules,
		setUser,
		setUsers,
		setCompanies,
		setFirstNameRules,
	]);
};
