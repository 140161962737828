import IOrder from 'models/Order';

const exportOrders = (orders: IOrder[], fileName: string) => {
	const blob = URL.createObjectURL(
		new Blob([JSON.stringify(orders)], {
			type: 'application/json',
		})
	);

	const a = document.createElement('a');
	a.href = blob;
	a.download = fileName;
	a.hidden = true;
	document.body.appendChild(a);
	a.innerHTML = 'x';
	a.click();
	a.remove();
};

export default exportOrders;