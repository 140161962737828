import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { TabOption } from 'models/TabOption';

import { useStyles } from './style';

interface Props {
	options: TabOption[];
}

const getDefaultTab = (options: TabOption[]) =>
	options.find((option) => option.isDefault) ?? options[0] ?? {};

const Tabs: FC<Props> = ({ options }) => {
	const { tabs, tab, tab_selected } = useStyles();
	const [currentTab, setCurrentTab] = useState(getDefaultTab(options));

	useEffect(() => {
		setCurrentTab(getDefaultTab(options));
	}, [options]);

	const handleOpenTab = (newTab: TabOption) => () => setCurrentTab(newTab);

	return (
		<>
			<Box className={tabs}>
				{options.map((option) => (
					<h3
						className={clsx(tab, {
							[tab_selected]: currentTab.value === option.value,
						})}
						onClick={handleOpenTab(option)}
					>
						{option.text}
					</h3>
				))}
			</Box>
			{currentTab.content ?? ''}
		</>
	);
};

export default Tabs;
