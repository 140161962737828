import { FC, MouseEvent } from 'react';
import { Box, Typography } from '@mui/material';
import { CheckOutlined } from '@mui/icons-material';
import clsx from 'clsx';

import IOrder from 'models/Order';

import { useStyles } from './style';

interface Props {
	order: IOrder;
	onClick: () => void;
	onClickCompletedBadge: (e: MouseEvent) => void;
}

const OrderItem: FC<Props> = ({ order, onClick, onClickCompletedBadge }) => {
	const { container, heading, content, completedBadge, completedBadgeActivated } = useStyles();
	const { isCompleted } = order;

	return (
		<Box className={container} onClick={onClick}>
			<h4 className={heading}>{order['Order ID']}</h4>
			<Box className={content}>
				<Typography
					fontWeight='bold'
					fontSize='14px'
				>{`${order['Last Name']}, ${order['First Name']} ${order['Middle Name']}`}</Typography>
				<CheckOutlined className={clsx(completedBadge, { [completedBadgeActivated]: isCompleted })} onClick={onClickCompletedBadge}/>
			</Box>
		</Box>
	);
};

export default OrderItem;
