import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexRowSpaceBetweenCenter } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		height: '64px',
		...flexRowSpaceBetweenCenter,
		backgroundColor: theme.palette.custom.common.black,
	},
	headerLogo: {
		height: '36px'
	},
	icon: {
		width: '40px !important',
		height: '40px !important'
	},
	reviewIcon: {
		transform: 'rotate3d(1, 0, 1, -45deg) translate(6px, -2px)',
	}
}));