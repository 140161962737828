import React, { FC, useState } from 'react';
import { Box } from '@mui/material';

import IOrder, { SentOrderResponse } from 'models/Order';

import ButtonAction from 'components/common/ButtonAction';
import ButtonSuccess from 'components/common/ButtonSuccess';
import { useSnackBar } from 'components/SnackBarContextProvider/hooks';

import { Repo } from 'network/Repo';

import NoRecordOrderList from './NoRecordOrderList';
import RecordOrderList from './RecordOrderList';
import SendOrdersResponseModal from './SendOrdersResponseModal';
import { useStyles } from './style';

interface Props {
	orders: IOrder[];
	setOrders: React.Dispatch<React.SetStateAction<IOrder[]>>;
	onCancelReview: () => void;
}

const Review: FC<Props> = ({ orders, setOrders, onCancelReview }) => {
	const snackBar = useSnackBar();
	const { container, content, btnContainer, backBtn } = useStyles();
	const [sentOrderResponses, setSentOrderResponses] = useState<
		SentOrderResponse[]
	>([]);

	const ordersWithRecords = orders.filter((order) => !order.noRecordsFound);
	const ordersWithoutRecords = orders.filter((order) => order.noRecordsFound);

	const submitOrders = async () => {
		const resp = await Repo.sendOrders(orders, snackBar.showError);
		setSentOrderResponses(resp);
	};

	const closeModal = () => {
		const successfulOrderIds = sentOrderResponses.filter(resp => resp.result === 'SUCCESS').map(resp => resp.id);
		setOrders(existingOrders => existingOrders.filter(order => !successfulOrderIds.includes(order['Order ID'])));
		setSentOrderResponses([]);
		onCancelReview();
	}

	return (
		<Box className={container}>
			<Box className={btnContainer}>
				<ButtonAction onClick={onCancelReview} className={backBtn}>
					Back to Edit
				</ButtonAction>
				<ButtonSuccess onClick={submitOrders} className={backBtn}>
					Submit Orders
				</ButtonSuccess>
			</Box>
			<Box className={content}>
				{ordersWithoutRecords.length > 0 && (
					<NoRecordOrderList orders={ordersWithoutRecords} />
				)}
				{ordersWithRecords.length > 0 && (
					<RecordOrderList orders={ordersWithRecords} />
				)}
			</Box>
			<SendOrdersResponseModal orders={sentOrderResponses} close={closeModal} />
		</Box>
	);
};

export default Review;
