import { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import ButtonAction from 'components/common/ButtonAction';
import IOrder from 'models/Order';

import formatCase from './util/formatCase';
import prefixDOBWithZero from './util/prefixDOBWithZero';
import { useStyles } from './style';
import byVerified from './util/byVerified';

interface Props {
	onUpload: (data: any) => void;
}

const UploadFileMessage: FC<Props> = ({ onUpload }) => {
	const { container, uploadIcon, uploadBtn } = useStyles();
	const [error, setError] = useState('');

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const fileReader = new FileReader();
			fileReader.readAsText(e.target.files[0], 'UTF-8');
			fileReader.onload = () => {
				setError('');

				try {
					const uploadedOrders: IOrder[] = JSON.parse(JSON.parse(
						JSON.stringify((fileReader.result ?? ''))
					));
					const orders = uploadedOrders.map((order: any) => {
						if (order.DOB) {
							order.DOB = prefixDOBWithZero(order.DOB);
						}

						const cases = order.cases
							.map(formatCase(order))
							.sort(
								byVerified(
									new Date(order.DOB).getTime(),
									order.SSN,
									order['First Name']
								)
							);
						return {
							...order,
							isCompleted:
								order.noRecordsFound || order.isCompleted,
							cases,
						};
					});

					onUpload(orders);
				} catch {
					setError(
						'Unable to upload file. If this file was downloaded from the PreDiscover Chrome Extension, please file an issue report.'
					);
				}
			};
		}
	};

	return (
		<Box className={container}>
			<CloudUploadIcon className={uploadIcon} />
			<h3>
				Upload a .json file from the PreDiscover Chrome Extension to
				begin.
			</h3>
			<ButtonAction className={uploadBtn}>
				<label>
					Choose File
					<input type='file' onChange={handleChange} accept='.json' />
				</label>
			</ButtonAction>
			{error && <Typography>{error}</Typography>}
		</Box>
	);
};

export default UploadFileMessage;
