import { makeStyles } from "@mui/styles";
import { flexColCenterCenter, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme) => ({
	container: {
		...fullWidth,
		...fullHeight,
		...flexColCenterCenter,
		backgroundColor: '#111'
	},
	loginLogoImg: {
		width: 'calc(100vw / 4)',
		height: 'calc((100vw / 4) * .385)',
		minWidth: '279px',
		minHeight: '108px'
	},
	loginForm: {
		...flexColCenterCenter,
		width: '400px',
		padding: '10px'
	},
	input: {
		width: '279px',
		marginBottom: '16px',
		marginTop: '8px'
	},
	loginBtn: {
		'&.MuiButton-root': {
			marginTop: '8px'
		}
	},
}))