import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: '200px',
		height: 'auto',
		backgroundColor: theme.palette.custom.common.white,
		margin: '0 16px 16px 0',
		boxShadow: `2px 2px 2px ${theme.palette.custom.common.lightGray}`,
		cursor: 'pointer',
	},
	content: {
		...fullWidth,
		padding: '8px',

		'& > h5': {
			margin: 0,
			borderBottom: `2px solid ${theme.palette.custom.common.black}`,
		},

		'& > p': {
			margin: '3px 0 0 0',
			fontSize: '12px'
		}
	},
	caseNumber: {
		...fullWidth,
		color: theme.palette.custom.common.white,
		backgroundColor: theme.palette.custom.common.purple.light,
		padding: '8px',
		margin: '0 auto',
	},
	msg: {
		fontWeight: 700,
		borderRadius: '5px',
		padding: '2px',
	},
	green: {
		color: 'green',
		border: '1px solid green',
	},
	red: {
		color: 'red',
	},
	verifiedIcon: {
		fontSize: '18px !important',
		backgroundColor: 'white',
		borderRadius: '50%',
		color: 'green',
		marginBottom: '-5px',
	},

}));
