import React, { FC } from 'react';
import {
	Navigate,
	useLocation,
} from 'react-router-dom';
import { Location } from 'history';
import IUser from 'models/User';
import isSuperAdmin from 'util/isSuperAdmin';

interface Props {
	user: IUser | null;
}

const SuperAdminRoute: FC<Props> = ({
    user,
    children,
}: any) => {
	const location = (useLocation() as Location);
	if(!user) return <Navigate to='/login' state={{ from: location }} replace/>;
	if(!isSuperAdmin(user)) return <Navigate to='/' />;

    return children;
};

export default SuperAdminRoute;
