interface PermissionObject {
	display: string;
	value: string;
}

export const toReadablePermission = (perm: string) => {
	const words = perm.split('_');
	return words.map(w => w[0].toUpperCase() + w.slice(1).toLowerCase()).join(' ');
}

export const toPermissionObjects = (perm: string): PermissionObject => ({
	display: toReadablePermission(perm),
	value: perm,
})

export const byName = (p1: PermissionObject, p2: PermissionObject) => p1.value < p2.value ? -1 : 1;