import { useContext, useEffect } from 'react';
import { Repo } from 'network/Repo';
import IUser from 'models/User';
import { AppContext } from 'components/AppContextProvider/context';

const init = async (
	handleSetUser: (user: IUser) => void,
	handleSetUsers: (companyId: string, users: IUser[]) => void,
	setIsLoading: (isLoading: boolean) => void
) => {
	const token = localStorage.getItem('preDiscover-jwt');
	if (token) {
		Repo.setAuthHeader(token);
		await Repo.getUser(handleSetUser, handleSetUsers);
	}

	setIsLoading(false);
};

export const useInit = (setIsLoading: (isLoading: boolean) => void) => {
	const { handleSetUser, handleSetUsers } = useContext(AppContext);
	useEffect(() => {
		init(handleSetUser, handleSetUsers, setIsLoading);
	}, []);
};
