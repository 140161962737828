import React, { FC } from 'react';
import { FormControlLabel, Switch } from '@mui/material';

interface SwitchProps {
	display: string;
	value: string;
	checked: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PermissionSwitch: FC<SwitchProps> = ({
	display,
	value,
	checked,
	onChange,
}) => (
	<FormControlLabel
		control={<Switch checked={checked} onChange={onChange} key={value} />}
		label={display}
		labelPlacement='end'
	/>
);

export default PermissionSwitch;