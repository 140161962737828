import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import ICase from 'models/Case';

import { useStyles } from './style';
import { CheckOutlined } from '@mui/icons-material';

interface Props {
	case: ICase;
	onClick: () => void;
}

const CaseCard: FC<Props> = ({ case: thisCase, onClick }) => {
	const { container, content, caseNumber, msg, red, green, verifiedIcon } = useStyles();

	const {
		'Case #': caseId,
		'First Name': firstName,
		'Last Name': lastName,
		'Middle Name': middleName,
		'Disposition Date': dispDate,
		'Filing Date': filingDate,
		verifiedBy,
		events: documents
	} = thisCase;

	const documentsAvailable = documents && documents.length;

	return (
		<Box className={container} onClick={onClick}>
			<h4 className={caseNumber}>{caseId}</h4>
			<Box className={content}>
				<h5>{`${lastName}, ${firstName} ${middleName}`}</h5>
				<p>FIRST {verifiedBy.includes('First Name') && <span><CheckOutlined className={verifiedIcon} /></span>}</p>
				<p>MIDDLE {verifiedBy.includes('Middle Name') && <span><CheckOutlined className={verifiedIcon} /></span>}</p>
				<p>LAST {verifiedBy.includes('Last Name') && <span><CheckOutlined className={verifiedIcon} /></span>}</p>
				<p>DOB {verifiedBy.includes('DOB') && <span><CheckOutlined className={verifiedIcon} /></span>}</p>
				<p>SSN {verifiedBy.includes('SSN') && <span><CheckOutlined className={verifiedIcon} /></span>}</p>
				<p className={clsx(msg, {[green]: documentsAvailable})}>{ documentsAvailable ? '' : 'NO '}DOCUMENTS AVAILABLE</p>
				{ !dispDate && (
					<p className={clsx(msg, red)}>NO DISP. DATE AVAILABLE</p>
				)}
				{ !filingDate && (
					<p className={clsx(msg, red)}>NO FILE DATE AVAILABLE</p>
				)}
			</Box>
		</Box>
	)
}

export default CaseCard;