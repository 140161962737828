import ICharge from './Charge';
import IEvent from './Event';

export const caseVerificationTypes = ['First Name', 'Middle Name', 'Last Name', 'DOB', 'SSN'];
export type TVerifiedBy = typeof caseVerificationTypes;

interface ICase {
	'Case #': string;
	Defendant: string;
	'First Name': string;
	'Middle Name': string;
	'Last Name': string;
	Status: string;
	DOB: string;
	'DOB Month': string;
	'DOB Day': string;
	'DOB Year': string;
	SSN: string;
	DL: string;
	'Filing Date': string;
	'Arrest Date': string;
	'Offense Date': string;
	'Disposition Date': string;
	'Type of Court': string;
	Address: string;
	Sex: string;
	Race: string;
	'License #': string;
	charges: ICharge[];
	events?: IEvent[];
	isIncluded: boolean;
	verifiedBy: TVerifiedBy;
	[key: string]: any;
}

export default ICase;