import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexCol, flexRowSpaceAround, flexRowStartEnd, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...flexCol,
		flex: 1,
		border: `1px solid ${theme.palette.custom.common.lightGray}`,
		boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);',
		borderRadius: '5px',

		'& > div:first-child': {
			marginBottom: '8px',
		}
	},
	heading: {
		...flexRowStartEnd,
		backgroundColor: theme.palette.custom.common.black,
		color: theme.palette.custom.common.white,
		padding: '8px',
		borderBottom: `1px solid ${theme.palette.custom.common.lightGray}`,
		borderTopLeftRadius: '5px',
		borderTopRightRadius: '5px',

		'& > h4': {
			margin: 0,
		}
	},
	contentContainer: {
		...fullWidth,
		...flexCol,
		flex: 1,
		padding: '0 8px',

		'& > div': {
			marginBottom: '8px',
		}
	},
	content: {
		...flexRowSpaceAround,

		'& > div': {
			maxWidth: '22%',
			marginBottom: 0,
		}
	},
}))