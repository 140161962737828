export interface CaseYearFilter {
	useFilter: boolean;
	property: 'Disposition' | 'Filing';
	years: number;
}

export const CASE_YEAR_FILTER_NAME = 'PD_CASE_YEAR_FILTER'

const DEFAULT_FILTER: CaseYearFilter = {
	useFilter: false,
	property: 'Disposition',
	years: 10
}

const getFilterFromLocalStorage = (): CaseYearFilter => {
	const filterFromLocalStorage = localStorage.getItem(CASE_YEAR_FILTER_NAME);
	return filterFromLocalStorage ? JSON.parse(filterFromLocalStorage) : DEFAULT_FILTER;
}

export default getFilterFromLocalStorage;