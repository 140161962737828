const GetRequest = {
	method: 'get',
	responseType: 'json',
};

const PostRequest = {
	method: 'post',
	responseType: 'json',
};

const PutRequest = {
	method: 'put',
	responseType: 'json',
};

const DeleteRequest = {
	method: 'delete',
	responseType: 'json',
	contentType: 'application/json',
};

const endpoints = {
	register: {
		...PostRequest,
		...{
			url: '/user',
		},
	},
	login: {
		...PostRequest,
		...{
			url: '/login',
		},
	},
	getUser: {
		...GetRequest,
		...{
			url: '/user',
			errorMessage: 'Unable to retrieve user: ',
		}
	},
	getUsers: {
		...GetRequest,
		...{
			url: '/users',
			errorMessage: 'Unable to retrieve users: ',
		}
	},
	changePassword: {
		...PutRequest,
		...{
			url: '/change-password',
		},
	},
	inviteUser: {
		...PutRequest,
		...{
			url: '/invite',
		},
	},
	resendInvite: {
		...PutRequest,
		...{
			url: '/resend-invite',
		},
	},
	revokeInvite: {
		...PutRequest,
		...{
			url: '/revoke-invite',
		},
	},
	deactivateLicense: {
		...PutRequest,
		...{
			url: '/deactivate-license',
		},
	},
	reactivateLicense: {
		...PutRequest,
		...{
			url: '/reactivate-license',
		},
	},
	sendOrders: {
		...PostRequest,
		...{
			url: 'orders',
			errorMessage: 'Unable to send orders: ',
		}
	},
	updateRoles: {
		...PutRequest,
		...{
			url: '/roles',
		},
	},
	registerCompany: {
		...PostRequest,
		...{
			url: '/company',
		},
	},
	getCompanies: {
		...GetRequest,
		...{
			url: '/companies',
		}
	},
	updateCompany: {
		...PutRequest,
		...{
			url: '/company',
		},
	},
	getAllFirstNameRules: {
		...GetRequest,
		...{
			url: '/all-first-name-rules',
		}
	},
	createFirstNameRule: {
		...PostRequest,
		...{
			url: '/first-name-rules',
		},
	},
	updateFirstNameRule: {
		...PutRequest,
		...{
			url: '/first-name-rules',
		},
	},
};

export default endpoints;