import { Tooltip as MuiTooltip, TooltipProps, Theme, tooltipClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	popper: {
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 'calc(100vw - 240px)',
			fontSize: '14px',
			fontWeight: 600,
			marginTop: '4px !important',
			border: '1px solid black',
			boxShadow: '2px 2px 5px black',
		},
	},
}));

const Tooltip: FC<TooltipProps> = ({ ...rest }) => {
	const { popper } = useStyles();

	return (
		<MuiTooltip classes={{ popper }} {...rest} />
	);
};

export default Tooltip;
