import { SelfImprovement } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { useStyles } from './style';

const NoRecordsMessage = () => {
	const { container, icon } = useStyles();

	return (
		<Box className={container}>
			<SelfImprovement className={icon} />
			<Typography>No records were found for this search. It is good to go!</Typography>
			<Typography>Feel free to exclude this search if you would like to handle it manually.</Typography>
		</Box>
	)
}

export default NoRecordsMessage;