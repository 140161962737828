import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexCol, flexRow, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...flexCol,
		flex: 1,
		paddingTop: '22px',
		overflowY: 'hidden',
	},
	innerContainer: {
		...flexRow,
		height: 'calc(100% - 32px)',
		padding: '0 16px',
	},
	generalPermissionsContainer: {
		width: '300px',
		...flexCol
	},
	countyPermissionsOuterContainer: {
		flex: 1,
		height: '100%',
		
	},
	countyPermissionsInnerContainer: {
		...flexCol,
		height: 'calc(100% - 32px)',
		paddingBottom: '16px',
		alignContent: 'start',
		flexWrap: 'wrap',

		'& > label': {
			width: '300px'
		}
	}
}))