import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexColCenterCenter } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	loginForm: {
		...flexColCenterCenter,
		width: '400px',
		padding: '10px'
	},
	input: {
		width: '279px',
		marginBottom: '16px',
		marginTop: '8px'
	},
	loginBtn: {
		'&.MuiButton-root': {
			marginTop: '8px'
		}
	},
}))