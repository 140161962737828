import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ICompany } from 'models/Company';
import { Repo } from 'network/Repo';

import { AppContext } from 'components/AppContextProvider/context';
import SettingsPage from 'components/common/SettingsPage';
import UserManagementSection from 'components/pages/UserManagementSection';
import isSuperAdmin from 'util/isSuperAdmin';
import PermissionManagementSection from './PermissionManagementSection';

enum Tabs {
	USERS = 'users',
	PERMISSIONS = 'permissions',
}

const CompanyEditPage = () => {
	const navigate = useNavigate();
	const {
		user,
		users,
		companies,
		handleSetCompanies,
		handleSetUsers,
	} = useContext(AppContext);

	if (!isSuperAdmin(user!)) navigate('/');

	const { companyId } = useParams();

	useEffect(() => {
		Repo.getCompanies(handleSetCompanies);
		if (companyId) Repo.getUsers(companyId, handleSetUsers);
	}, []);

	const company = companies.find((comp: ICompany) => comp.id === companyId);
	const handleSetCompany = (comp: ICompany) =>
		handleSetCompanies(companies.map((c) => (c.id === comp.id ? comp : c)));

	const tabOptions = useMemo(() => {
		return company
			? [
					{
						value: Tabs.USERS,
						text: 'Users',
						isDefault: true,
						content: (
							<UserManagementSection
								company={company}
								users={users[company.id] ?? []}
								onSetCompany={handleSetCompany}
							/>
						),
					},
					{
						value: Tabs.PERMISSIONS,
						text: 'Permissions',
						content: (
							<PermissionManagementSection company={company} />
						),
					},
			  ]
			: [];
	}, [company]);

	return <SettingsPage title={company?.name ?? ''} tabs={tabOptions} />;
};

export default CompanyEditPage;
