import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SettingsPage from 'components/common/SettingsPage';
import ButtonAction from 'components/common/ButtonAction';
import TextFieldOutlined from 'components/common/TextFieldOutlined';

import { AppContext } from 'components/AppContextProvider/context';
import { useSnackBar } from 'components/SnackBarContextProvider/hooks';

import { Repo } from 'network/Repo';
import isSuperAdmin from 'util/isSuperAdmin';
import { createValidationMap, containsInputErrors } from 'util/formValidation';

import { useStyles } from './style';

const CompanyRegisterPage = () => {
	const navigate = useNavigate();
	const { user } = useContext(
		AppContext
	);

	if (!isSuperAdmin(user!)) navigate('/');
	const { container, form, submitBtn } = useStyles();
	const snackBar = useSnackBar();

	const [companyName, setCompanyName] = useState('');
	const [email, setEmail] = useState('');
	const [numOfLicenses, setNumOfLicenses] = useState(5);
	const [delveUsername, setDelveUsername] = useState('');
	const [delvePassword, setDelvePassword] = useState('');
	const [delveUrl, setDelveUrl] = useState('');
	const [hasCompanyNameError, setHasCompanyNameError] = useState(false);
	const [hasEmailError, setHasEmailError] = useState(false);
	const [hasNumOfLicensesError, setHasNumOfLicensesError] = useState(false);

	const handleRegister = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		const validationMaps = [
			createValidationMap(companyName, setHasCompanyNameError),
			createValidationMap(email, setHasEmailError),
			createValidationMap(numOfLicenses, setHasNumOfLicensesError),
		];

		if (containsInputErrors(validationMaps)) return;

		const newCompany = await Repo.registerCompany(
			companyName,
			email,
			numOfLicenses,
			delveUsername,
			delvePassword,
			delveUrl,
			snackBar.showError
		);

		if (newCompany) navigate(`/settings/companies/${newCompany.id}`);
	};

	const handleChange = (
		setFn: any,
		setHasErrorFn?: (val: boolean) => void
	) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setFn(e.target.value);
		if (setHasErrorFn) setHasErrorFn(false);
	};

	return (
		<SettingsPage title='New Company' className={container}>
			<form className={form} onSubmit={handleRegister}>
				<TextFieldOutlined
					label='Company Name'
					value={companyName}
					error={hasCompanyNameError}
					onInput={handleChange(
						setCompanyName,
						setHasCompanyNameError
					)}
				/>
				<TextFieldOutlined
					label='Email'
					value={email}
					error={hasEmailError}
					onInput={handleChange(setEmail, setHasEmailError)}
				/>
				<TextFieldOutlined
					label='# of Permissions'
					value={numOfLicenses}
					error={hasNumOfLicensesError}
					type='number'
					onInput={handleChange(
						setNumOfLicenses,
						setHasNumOfLicensesError
					)}
				/>
				<TextFieldOutlined
					label='DigitalDelve Username'
					value={delveUsername}
					onInput={handleChange(setDelveUsername)}
				/>
				<TextFieldOutlined
					label='DigitalDelve Password'
					value={delvePassword}
					onInput={handleChange(setDelvePassword)}
				/>
				<TextFieldOutlined
					label='DigitalDelve URL'
					value={delveUrl}
					onInput={handleChange(setDelveUrl)}
				/>
				<ButtonAction
					variant='outlined'
					type='submit'
					className={submitBtn}
				>
					Submit
				</ButtonAction>
			</form>
		</SettingsPage>
	);
};

export default CompanyRegisterPage;
