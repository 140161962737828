import React, { FC, useMemo } from 'react';
import { Box } from '@mui/material';

import IUser from 'models/User';

import { useStyles } from './style';

interface Props {
	user: IUser;
}

const General: FC<Props> = ({ user }) => {
	const { container, content } = useStyles();

	const roles = useMemo(() => {
		return user?.roles
			.map((role) => {
				const words =
					role === 'ADMIN'
						? 'SUPER_ADMIN'.split('_')
						: role.split('_');
				return words
					.map(
						(word) =>
							word[0].toUpperCase() + word.slice(1).toLowerCase()
					)
					.join(' ');
			})
			.join(', ');
	}, [user?.roles]);

	return (
		<Box className={container}>
			<h2>General</h2>
			<Box className={content}>
				<p>
					Name:{' '}
					<strong>
						{user.firstName} {user.lastName}
					</strong>
				</p>
				<p>
					Email: <strong>{user.email}</strong>
				</p>
				<p>
					Company: <strong>{user.company.name}</strong>
				</p>
				<p>
					Roles: <strong>{roles}</strong>
				</p>
			</Box>
		</Box>
	);
};

export default General;
