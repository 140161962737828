import { useContext, useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import LoginLogo from 'assets/loginLogo.svg';
import { Repo } from 'network/Repo';
import { containsInputErrors, createValidationMap } from 'util/formValidation';
import { AppContext } from 'components/AppContextProvider/context';
import { useSnackBar } from 'components/SnackBarContextProvider/hooks';

import { useStyles } from './style';

const LoginPage = () => {
	const { user, handleSetUser, handleSetUsers } = useContext(AppContext);
	const snackBar = useSnackBar();

	const { email: emailFromParams } = useParams();
	const navigate = useNavigate();
	const location: any = useLocation();
	const prevPath = location.state?.from?.pathname || '/';
	const from = prevPath.includes('register') ? '/' : prevPath;

	const [email, setEmail] = useState(emailFromParams ?? '');
	const [password, setPassword] = useState('');
	const [hasEmailError, setHasEmailError] = useState(false);
	const [hasPasswordError, setHasPasswordError] = useState(false);
	const {
		container,
		loginLogoImg,
		loginForm,
		input,
		loginBtn,
	} = useStyles();

	useEffect(() => {
		if (user) {
			navigate(from, { replace: true });
		}
	}, [user]);

	const handleLogin = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		const validationMaps = [
			createValidationMap(email, setHasEmailError),
			createValidationMap(password, setHasPasswordError),
		];

		if (containsInputErrors(validationMaps)) return;

		const isAuthenticated = await Repo.login(email, password, snackBar.showError);
		if (isAuthenticated) {
			await Repo.getUser(handleSetUser, handleSetUsers);
			
			navigate(from, { replace: true });
		}
	};

	const handleChange = (
		setFn: (val: string) => void,
		setHasErrorFn: (val: boolean) => void
	) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setFn(e.target.value);
		setHasErrorFn(false);
	};

	return (
		<div className={container}>
			<img
				src={LoginLogo}
				className={loginLogoImg}
				alt='PreDiscover by VersaDev Logo'
			/>
			<form className={loginForm} onSubmit={handleLogin}>
				<TextField
					variant='outlined'
					margin='dense'
					placeholder='Email'
					value={email}
					error={hasEmailError}
					onInput={handleChange(setEmail, setHasEmailError)}
					className={input}
				/>
				<TextField
					variant='outlined'
					margin='dense'
					placeholder='Password'
					value={password}
					error={hasPasswordError}
					onInput={handleChange(setPassword, setHasPasswordError)}
					className={input}
					type='password'
				/>
				<Button variant='outlined' type='submit' className={loginBtn}>
					Submit
				</Button>
			</form>
		</div>
	);
};

export default LoginPage;
