import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexRowStartCenter } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	filterContainer: {
		...flexRowStartCenter,
		marginTop: '8px',
	},
	tableContainer: {
		flex: 1,
		marginTop: '8px',
		overflowY: 'auto',

	},
}))