import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexRowStartCenter } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'inline-flex',
		marginLeft: '8px',
	},
	btn: {
		minWidth: '24px !important',
		padding: '4px !important',
		borderRadius: '50% !important',
		borderWidth: '2px !important',
	},
	popover: {
		...flexRowStartCenter,
		padding: '8px',

		'& * legend': {
			display: 'none',
		},

		'& fieldset': {
			top: 0,
		},
	},
	textFieldContainer: {
		width: '40px !important',
		margin: '0 !important',
	},
	textField: {
		padding: '4px !important',
	},
	checkbox: {
		padding: '0 4px 0 0 !important',
	},
	select: {
		padding: '4px !important',
		paddingRight: '20px !important',
	},
	selectIcon: {
		right: '0 !important',
	},
}));
