import React, { FC, useState } from 'react';
import { Box, TextField } from '@mui/material';

import { useSnackBar } from 'components/SnackBarContextProvider/hooks';
import ButtonAction from 'components/common/ButtonAction';

import IUser from 'models/User';
import { Repo } from 'network/Repo';
import { containsInputErrors, createValidationMap } from 'util/formValidation';

import { useStyles } from './style';

enum Errors {
	missingData = 'Please fill in all fields.',
	passwordsDoNotMatch = 'New password fields do not match.',
}

interface Props {
	user: IUser;
}

const ChangePassword: FC<Props> = ({ user }) => {
	const { container, form, input, submitBtn } = useStyles();
	const snackBar = useSnackBar();
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');

	const handleInputError = (error: string) => () => snackBar.showError(error);

	const handleRegister = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		const validationMaps = [
			createValidationMap(password, handleInputError(Errors.missingData)),
			createValidationMap(
				newPassword,
				handleInputError(Errors.missingData)
			),
			createValidationMap(
				confirmNewPassword,
				handleInputError(Errors.missingData)
			),
		];

		if (containsInputErrors(validationMaps, true)) return;
		else if (newPassword !== confirmNewPassword) {
			handleInputError(Errors.passwordsDoNotMatch)();
			return;
		}

		const updatedUser = await Repo.changePassword(
			user.id,
			password,
			newPassword,
			snackBar.showError
		);

		if (updatedUser) {
			snackBar.showSuccess('Password successfully changed');
			setPassword('');
			setNewPassword('');
			setConfirmNewPassword('');
		}
	};

	const handleChange = (setFn: (val: string) => void) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setFn(e.target.value);
	};

	return (
		<Box className={container}>
			<form className={form}>
				<TextField
					variant='outlined'
					margin='dense'
					placeholder='Current Password'
					value={password}
					onInput={handleChange(setPassword)}
					className={input}
					type='password'
				/>
				<TextField
					variant='outlined'
					margin='dense'
					placeholder='New Password'
					value={newPassword}
					onInput={handleChange(setNewPassword)}
					className={input}
					type='password'
				/>
				<TextField
					variant='outlined'
					margin='dense'
					placeholder='Confirm New Password'
					value={confirmNewPassword}
					onInput={handleChange(setConfirmNewPassword)}
					className={input}
					type='password'
				/>
				<ButtonAction className={submitBtn} onClick={handleRegister}>
					Submit
				</ButtonAction>
			</form>
		</Box>
	);
};

export default ChangePassword;
