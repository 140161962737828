export enum LicenseStatuses {
	AVAILABLE = 'AVAILABLE',
	INVITE_SENT = 'INVITE SENT',
	IN_USE = 'IN USE',
	INACTIVE = 'INACTIVE',
}

export interface IUserLicense {
	id: string;
	status: LicenseStatuses;
	email?: string;
	userName?: string;
	statusChangedDateTimeUTC?: Date;
}

export interface ICompany {
	id: string;
	name: string;
	email: string;
	createDateTimeUTC: Date;
	permissions: string[];
	licenses?: IUserLicense[];
}
