import React, { FC } from 'react';
import {
	Navigate,
	useLocation,
} from 'react-router-dom';
import { Location } from 'history';
import IUser from 'models/User';
import { GeneralPermissions } from 'constants/permissions';

interface Props {
	user: IUser | null;
}

const OrderSubmissionRoute: FC<Props> = ({
    user,
    children,
}: any) => {
	const location = (useLocation() as Location);
	if(!user) return <Navigate to='/login' state={{ from: location }} replace/>;
	if(!user.company.permissions.includes(GeneralPermissions.ORDER_SUBMISSION_APP)) return <Navigate to='/settings/account' />;

    return children;
};

export default OrderSubmissionRoute;
