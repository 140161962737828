import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Select, MenuItem, Checkbox, SelectChangeEvent } from '@mui/material';

import Popover from 'components/common/Popover';
import TextFieldOutlined from 'components/common/TextFieldOutlined';
import { FC } from 'react';
import { CaseYearFilter } from '../util/getFilterFromLocalStorage';

import { useStyles } from './style';

interface Props {
	filter: CaseYearFilter;
	handleChangeYears: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleChangeProperty: (
		e: SelectChangeEvent<'Disposition' | 'Filing'>
	) => void;
	handleChangeUseFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CaseFilter: FC<Props> = ({
	filter,
	handleChangeYears,
	handleChangeProperty,
	handleChangeUseFilter,
}) => {
	const {
		container,
		btn,
		popover,
		checkbox,
		textFieldContainer,
		textField,
		select,
		selectIcon,
	} = useStyles();

	return (
		<Popover
			className={container}
			buttonText={<FilterListIcon />}
			buttonClassName={btn}
			content={
				<Box className={popover}>
					<Checkbox
						checked={filter.useFilter}
						className={checkbox}
						onChange={handleChangeUseFilter}
					/>
					Show cases with&nbsp;
					<Select
						labelId='date-type-label'
						id='date-type-select'
						value={filter.property}
						label='Date Type'
						classes={{ outlined: select, icon: selectIcon }}
						onChange={handleChangeProperty}
					>
						<MenuItem value='Disposition'>Disposition</MenuItem>
						<MenuItem value='Filing'>Filing</MenuItem>
					</Select>
					&nbsp;date within the past&nbsp;
					<TextFieldOutlined
						value={filter.years}
						onInput={handleChangeYears}
						type='number'
						className={textFieldContainer}
						inputProps={{
							className: textField,
						}}
					/>{' '}
					&nbsp;years
				</Box>
			}
		/>
	);
};

export default CaseFilter;
