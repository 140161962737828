import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: '4px 8px',
		cursor: 'pointer',
		color: `${theme.palette.custom.common.black} !important`,

		'&:hover': {
			border: `2px solid ${theme.palette.custom.common.purple.dark}`,
			borderRadius: '5px',
		}
	}
}));