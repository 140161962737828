import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AppContext } from 'components/AppContextProvider/context';

import HeaderBar from '../../HeaderBar';

import { useStyles } from './style';

const RegisterPage = () => {
	const { user } = useContext(AppContext);
	if (user) window.history.back();

	const {
		container,
		content,
	} = useStyles();

	return (
		<div className={container}>
			<HeaderBar />
			<Box className={content}>
				<Outlet />
			</Box>
		</div>
	);
};

export default RegisterPage;
