import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexCol, flexRowEnd, flexRowSpaceBetweenCenter, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	dialogContent: {
		...flexCol,
		width: '100%',
		maxHeight: 'calc(100vh - 216px)',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
		overflowY: 'auto',
	},
	buttonContainer: {
		...flexRowEnd,
		height: 'auto',
		padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
		borderTop: '1px solid #E0E0E0',
	},
	dots: {
		width: '100%',
		height: '26px',
		borderBottom: 'dotted gray',
		marginTop: '8px',
	},
	orderContainer: {
		...flexRowSpaceBetweenCenter,
		...fullWidth,
		marginTop: '-26px',
		padding: '8px 0',
	},
	text: {
		padding: '0 8px',
		float: 'left',
		backgroundColor: '#fff',
		fontSize: '18px',
	},
	successText: {
		color: theme.palette.custom.common.green.primary
	},
	errorText: {
		color: theme.palette.custom.common.red.primary
	}
}));
