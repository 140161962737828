import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import React, { FC } from 'react';

interface Props {
	label: string;
	labelClassName?: string;
	labelPlacement?: "end" | "start" | "top" | "bottom";
}

const CheckboxWithLabel: FC<CheckboxProps & Props> = ({
	label,
	labelClassName = '',
	labelPlacement = 'end',
	...rest
}): JSX.Element => (
	<FormControlLabel label={label} labelPlacement={labelPlacement} className={labelClassName} control={<Checkbox {...rest} />} />
);

export default CheckboxWithLabel;
