import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexColCenterCenter, fullHeight } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullHeight,
		...flexColCenterCenter,
	},
	form: {
		...flexColCenterCenter,
		width: '400px',
		padding: '10px'
	},
	submitBtn: {
		'&.MuiButton-root': {
			marginTop: '8px'
		}
	},
}))