import { lighten, Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexCol, flexColCenterCenter, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...fullHeight,
		...flexCol,
		overflow: 'hidden',
		backgroundColor: lighten(theme.palette.custom.common.lightGray, .6),
	},
	content: {
		...fullWidth,
		...fullHeight,
		...flexColCenterCenter,
	},
}))