import React, { FC, useMemo, useRef } from 'react';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
	Table,
	TableCell,
	TableRow,
	TableBody,
	Switch,
	Typography,
} from '@mui/material';
import clsx from 'clsx';

import ICase, { caseVerificationTypes } from 'models/Case';
import IEvent from 'models/Event';

import TextFieldOutlined from 'components/common/TextFieldOutlined';
import CheckboxWithLabel from 'components/common/CheckboxWithLabel';
import ButtonSuccess from 'components/common/ButtonSuccess';
import Popover from 'components/common/Popover';
import TextArea from 'components/common/TextArea';

import { flexRowStartCenter } from 'styles/stylesLib';

import Charge from './Charge';
import { useChargesData } from './hooks';
import { useStyles } from './style';
import Document from './Document';

interface Props {
	case: ICase;
	caseNum?: number;
	title: string;
	onSubmit: (newCase: ICase) => void;
}

const Case: FC<Props> = ({ case: thisCase, caseNum, title, onSubmit }) => {
	const ref = useRef<Element>();

	const {
		container,
		list,
		maxWidth1000,
		headingContainer,
		heading,
		actionsContainer,
		includeSwitch,
		documentBtnContainer,
		documentLinksContainer,
		unsavedMsg,
	} = useStyles();

	const {
		tempCase,
		hasChanges,
		handleInputChange,
		handleTextAreaChange,
		handleSwitchChange,
		handleVerifiedByCheckboxChange,
		handleChangeCharge,
		handleChangeDOB,
		handleSaveChanges,
	} = useChargesData(thisCase, onSubmit);

	const { Sex, charges, isIncluded, events = [], verifiedBy = [] } = tempCase;
	const documents = useMemo(
		() => events.filter((e: IEvent) => Boolean(e.Image)),
		[events]
	);

	return (
		<Box className={container} ref={ref}>
			<Box className={clsx('heading', headingContainer)}>
				<h2 className={heading}>{title}</h2>
				<Box className={actionsContainer}>
					{hasChanges && (
						<>
							<p className={unsavedMsg}>
								Unsaved Changes Detected
							</p>
							<ButtonSuccess
								disabled={!hasChanges && isIncluded}
								onClick={handleSaveChanges}
							>{`Save Case`}</ButtonSuccess>
						</>
					)}
					<FormControlLabel
						className={includeSwitch}
						control={
							<Switch
								checked={isIncluded}
								onChange={handleSwitchChange('isIncluded')}
							/>
						}
						label='Included'
					/>
				</Box>
			</Box>
			<Box className={documentBtnContainer}>
				<Table className={maxWidth1000} size='small'>
					<TableBody>
						<TableRow>
							<TableCell>Record verified by:</TableCell>
							{caseVerificationTypes.map((type) => (
								<TableCell key={type.replaceAll(' ', '')}>
									<CheckboxWithLabel
										key={`verified-by-${type.replaceAll(
											' ',
											''
										)}`}
										label={type}
										checked={verifiedBy.includes(type)}
										onChange={handleVerifiedByCheckboxChange(
											type
										)}
									/>
								</TableCell>
							))}
						</TableRow>
					</TableBody>
				</Table>
				{documents.length ? (
					<Popover
						buttonText='Documents'
						content={
							<Box className={documentLinksContainer}>
								{documents.map((e: IEvent) => (
									<Document doc={e} key={e.key} />
								))}
							</Box>
						}
					/>
				) : (
					<Typography>&nbsp;(No Documents Available)</Typography>
				)}
			</Box>
			<Table size='small'>
				<TableBody>
					<TableRow>
						<TableCell>
							<TextFieldOutlined
								label='First Name'
								value={tempCase['First Name']}
								onInput={handleInputChange('First Name')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Middle Name'
								value={tempCase['Middle Name']}
								onInput={handleInputChange('Middle Name')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Last Name'
								value={tempCase['Last Name']}
								onInput={handleInputChange('Last Name')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Birth Month'
								value={tempCase['DOB Month']}
								onInput={handleChangeDOB('Month')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Birth Day'
								value={tempCase['DOB Day']}
								onInput={handleChangeDOB('Day')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Birth Year'
								value={tempCase['DOB Year']}
								onInput={handleChangeDOB('Year')}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TextFieldOutlined
								label='SSN'
								value={tempCase['SSN']}
								onInput={handleInputChange('SSN')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='DL'
								value={tempCase['DL']}
								onInput={handleInputChange('DL')}
							/>
						</TableCell>
						<TableCell>
							<FormControl component='fieldset'>
								<FormLabel component='legend'>Gender</FormLabel>
								<RadioGroup
									aria-label='sex'
									name='sex'
									value={Sex ?? ''}
									style={{ ...flexRowStartCenter }}
									onChange={handleInputChange('Sex')}
								>
									<FormControlLabel
										value='FEMALE'
										control={<Radio />}
										label='Female'
									/>
									<FormControlLabel
										value='MALE'
										control={<Radio />}
										label='Male'
									/>
									<FormControlLabel
										value=''
										control={<Radio />}
										label='Not Reported'
									/>
								</RadioGroup>
							</FormControl>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Ethnicity'
								value={tempCase['Race']}
								onInput={handleInputChange('Race')}
							/>
						</TableCell>
						<TableCell colSpan={2}>
							<TextFieldOutlined
								label='Address'
								value={tempCase['Address']}
								onInput={handleInputChange('Address')}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell colSpan={6}>
							<TextArea
								label='Additional ID Info'
								value={tempCase['Additional ID Info'] ?? ''}
								onChange={handleTextAreaChange(
									'Additional ID Info'
								)}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TextFieldOutlined
								label='Case #'
								value={tempCase['Case #']}
								onInput={handleInputChange('Case #')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Offense Date'
								value={tempCase['Offense Date']}
								onInput={handleInputChange('Offense Date')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Arrest Date'
								value={tempCase['Arrest Date']}
								onInput={handleInputChange('Arrest Date')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='File Date'
								value={tempCase['Filing Date']}
								onInput={handleInputChange('Filing Date')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Disposition Date'
								value={tempCase['Disposition Date']}
								onInput={handleInputChange('Disposition Date')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Type of Court'
								value={tempCase['Type of Court']}
								onInput={handleInputChange('Type of Court')}
							/>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<Box className={list}>
				{charges.map((charge, i) => (
					<Charge
						charge={charge}
						chargeNum={i + 1}
						key={charge.key}
						onChange={handleChangeCharge(i)}
					/>
				))}
			</Box>
		</Box>
	);
};

export default Case;
