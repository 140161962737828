import React, { useContext, useMemo } from 'react';

import { AppContext } from 'components/AppContextProvider/context';
import SettingsPage from 'components/common/SettingsPage';

import General from './General';
import ChangePassword from './ChangePassword';
import { useStyles } from './style';

const AccountPage = () => {
	const { container } = useStyles();
	const { user } = useContext(AppContext);

	const tabOptions = useMemo(() => {
		return user
			? [
					{
						value: 'general',
						text: 'General',
						isDefault: true,
						content: <General user={user} />,
					},
					{
						value: 'change-password',
						text: 'Change Password',
						content: <ChangePassword user={user} />
					}
			  ]
			: [];
	}, [user]);

	return (
		<SettingsPage
			title='My Account'
			className={container}
			tabs={tabOptions}
		/>
	);
};

export default AccountPage;
