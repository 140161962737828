import { FC, useContext } from 'react';

import IFirstNameRule from 'models/FirstNameRule';

import ButtonSuccess from 'components/common/ButtonSuccess';
import ButtonDanger from 'components/common/ButtonDanger';
import { useSnackBar } from 'components/SnackBarContextProvider/hooks';
import { AppContext } from 'components/AppContextProvider/context';

import { useStyles } from './style';
import { Repo } from 'network/Repo';

type ListType = 'suggestedNames' | 'acceptedNames' | 'excludedNames';
type Action = 'accept' | 'exclude';

interface Props {
	ruleName: string;
	name: string;
	list: ListType;
	applyOppositeRule?: boolean;
	onSetRule: (rule: IFirstNameRule, isOppositeRule?: boolean) => void;
}

const createStep = (
	name: string,
	action: 'addTo' | 'removeFrom',
	list: ListType
) => ({
	names: [name],
	action,
	list,
});

const RuleActionOption: FC<Props> = ({
	ruleName,
	name,
	list,
	applyOppositeRule,
	onSetRule,
}) => {
	const { suggestion, btn } = useStyles();
	const snackBar = useSnackBar();
	const { firstNameRules } = useContext(AppContext);

	const handleAction = (
		action: Action,
		ruleNameOverride?: string,
		nameOverride?: string
	) => async () => {
		const newList: ListType =
			action === 'accept' ? 'acceptedNames' : 'excludedNames';

		let savedRule;
		if (firstNameRules.find((rule) => rule.name === (ruleNameOverride ?? ruleName))) {
			const ruleWithSteps = {
				name: ruleNameOverride ?? ruleName,
				steps: [
					createStep(nameOverride ?? name, 'addTo', newList),
					createStep(nameOverride ?? name, 'removeFrom', list),
				],
			};

			savedRule = await Repo.updateFirstNameRule(
				ruleWithSteps,
				snackBar.showError
			);
		} else {
			const newRule: IFirstNameRule = {
				name: ruleNameOverride ?? ruleName,
				acceptedNames: [],
				excludedNames: [],
				suggestedNames: [],
			};

			newRule[newList] = [nameOverride ?? name];
			savedRule = await Repo.createFirstNameRule(
				newRule,
				snackBar.showError
			);
		}

		onSetRule(savedRule, !!ruleNameOverride);
		snackBar.showSuccess('Rule Updated');

		if (applyOppositeRule && !ruleNameOverride) {
			handleAction(action, name, ruleName)();
		}
	};
	return (
		<p className={suggestion}>
			{(list === 'suggestedNames' || list === 'excludedNames') && (
				// @ts-ignore
				<ButtonSuccess onClick={handleAction('accept')} className={btn}>
					Accept
				</ButtonSuccess>
			)}
			{(list === 'suggestedNames' || list === 'acceptedNames') && (
				// @ts-ignore
				<ButtonDanger onClick={handleAction('exclude')} className={btn}>
					Exclude
				</ButtonDanger>
			)}
			{name}
		</p>
	);
};

export default RuleActionOption;
