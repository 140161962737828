import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { makeStyles } from '@mui/styles';

const labelSmall: CSSProperties = {
	top : '3px',
	left: 0,
	lineHeight: '.875em',
	transform: 'translate(14px, -9px) scale(0.75)',
	backgroundColor: '#fff',
}

export const useStyles = makeStyles((theme: Theme) => ({
	labelRoot: {
		color: 'rgba(0, 0, 0, 0.6)',
		'& legend': {
			visibility: 'visible',
			fontFamily:
				'Rubik,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
			fontWeight: 400,
			padding: 0,
			display: 'block',
			transformOrigin: 'top left',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			maxWidth: 'calc(133% - 24px)',
			position: 'absolute',
			top: '14px',
			left: '10px',
			transition:
				'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,maxWidth 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
			zIndex: 1,
			pointerEvents: 'auto',
			userSelect: 'none',
			lineHeight: '1em',
			fontSize: '.875rem',

		},

		'&:focus, &:active': labelSmall,
	},
	labelSmall: {
		'&.MuiOutlinedInput-notchedOutline': {
			overflow: 'visible'
		},

		'& legend': labelSmall,
	},
	textarea: {
		width: '100%',
		height: 'auto',
		marginTop: '8px',
		fontFamily:
			'Rubik,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
		fontWeight: 400,
		lineHeight: '1.4375em',
		boxSizing: 'border-box',
		position: 'relative',
		cursor: 'text',
		display: 'inline-flex',
		alignItems: 'center',
		color: '#282c34',
		backgroundColor: '#FFFFFF',
		fontSize: '14px',
		borderRadius: '0.5em',

		'& > textarea': {
			height: 'auto',
			resize: 'vertical',
		},
	},
}));
