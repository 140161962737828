import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexCol, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...fullHeight,
		...flexCol,
	},
}))