import { FC } from 'react';
import { AppContext } from './context';
import { useAppContextValue } from './hooks'

const AppProvider: FC = ({ children }) => {
	const contextValue = useAppContextValue();

	return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export default AppProvider;