import { Snackbar, Alert } from '@mui/material';
import React from 'react';
import { SnackBarContext, SnackBarContextProviderProps } from './context';

export type Color = 'info' | 'success' | 'warning' | 'error' | undefined;

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
	children,
}) => {
	const [open, setOpen] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<string>('');
	const [typeColor, setTypeColor] = React.useState<Color>('info');

	const showSnackBar = (text: string, color: Color) => {
		setMessage(text);
		setTypeColor(color);
		setOpen(true);
	};

	const showSuccess = (text: string) => showSnackBar(text, 'success');
	const showError = (text: string) => showSnackBar(text, 'error');
	const showWarning = (text: string) => showSnackBar(text, 'warning');
	const showInfo = (text: string) => showSnackBar(text, 'info');

	const handleClose = () => {
		setOpen(false);
		setTypeColor('info');
	};

	return (
		<SnackBarContext.Provider value={{ showSuccess, showError, showWarning, showInfo, }}>
			<Snackbar
				open={open}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity={typeColor} elevation={6}>
					{message}
				</Alert>
			</Snackbar>
			{children}
		</SnackBarContext.Provider>
	);
};

export default SnackBarProvider;
