import React, { FC } from 'react';
import clsx from 'clsx';

import Modal from 'components/common/Modal/Modal';
import ButtonSuccess from 'components/common/ButtonSuccess';
import { SentOrderResponse } from 'models/Order';
import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { useStyles } from './style';

interface Props {
	orders: SentOrderResponse[];
	close: () => void;
}

const SendOrdersResponseModal: FC<Props> = ({ orders, close }) => {
	const {
		dialogContent,
		buttonContainer,
		orderContainer,
		dots,
		text,
		successText,
		errorText,
	} = useStyles();

	return (
		<Modal isOpen={orders.length > 0}>
			<DialogTitle>Sent Orders</DialogTitle>
			<DialogContent classes={{ root: dialogContent }}>
				{orders.map(({ id, result }) => (
					<>
						<Box className={dots}></Box>
						<Box className={orderContainer}>
							<strong className={text}>{id}</strong>
							<strong
								className={clsx(text, {
									[successText]: result === 'SUCCESS',
									[errorText]: result === 'ERROR',
								})}
							>
								{result}
							</strong>
						</Box>
					</>
				))}
			</DialogContent>
			<DialogActions className={buttonContainer}>
				<ButtonSuccess onClick={close}>Okay</ButtonSuccess>
			</DialogActions>
		</Modal>
	);
};

export default SendOrdersResponseModal;
