import { darken, styled } from '@mui/material/styles';
import ButtonOutlined from '../ButtonOutlined';

const ButtonSuccess = styled(ButtonOutlined)(({ theme }) => ({
	'&.MuiButton-outlined': {
		backgroundColor: theme.palette.custom.common.green.primary,
		border: `1px solid ${theme.palette.custom.common.green.primary}`,

		'&:hover': {
			backgroundColor: theme.palette.custom.common.green.dark,
			borderColor: darken(theme.palette.custom.common.green.dark, .3),
		},

		'&:focus': {
			boxShadow: `0 0 0 0.2rem ${theme.palette.custom.common.green.dark}`,
		},

		'&:active': {
			backgroundColor: theme.palette.custom.common.green.dark,
			borderColor: darken(theme.palette.custom.common.green.dark, .3),
		},

		'&:disabled': {
			backgroundColor: theme.palette.custom.common.lightGray,
			borderColor: theme.palette.custom.common.lightGray,
			color: theme.palette.custom.common.darkGray,
			cursor: 'not-allowed',
		},
	},
}));

export default ButtonSuccess;