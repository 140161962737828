import { FC, useState, MouseEvent } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import IOrder from 'models/Order';
import ICase from 'models/Case';

import OrderItem from './OrderItem';
import { useStyles } from './style';

interface Props {
	orders: IOrder[];
	setSelectedOrderId: (orderId: string) => void;
	onChange: (orderId: string) => (property: string, newValue: string | boolean | ICase[]) => void;
}

const OrderSelector: FC<Props> = ({ orders, setSelectedOrderId, onChange }) => {
	const {
		container,
		switcher,
		switcherOption,
		selectedSwitcherOption,
		orderList,
	} = useStyles();
	const [showOrdersWithRecords, setShowOrdersWithRecords] = useState(true);

	const handleOrderClick = (orderId: string) => () =>
		setSelectedOrderId(orderId);

	const toggleSwitcher = () =>
		setShowOrdersWithRecords(!showOrdersWithRecords);

	const handleClickCompletedBadge = (order: IOrder) => (e: MouseEvent) => {
		e.stopPropagation();
		onChange(order['Order ID'])('isCompleted', !order.isCompleted);
	}

	const numOfOrdersWithRecords = orders.filter(o => !o.noRecordsFound).length;

	return (
		<Box className={container}>
			<Box className={switcher}>
				<Box
					className={clsx(switcherOption, {
						[selectedSwitcherOption]: showOrdersWithRecords,
					})}
					onClick={toggleSwitcher}
				>
					<h5>Records ({numOfOrdersWithRecords})</h5>
				</Box>
				<Box
					className={clsx(switcherOption, {
						[selectedSwitcherOption]: !showOrdersWithRecords,
					})}
					onClick={toggleSwitcher}
				>
					<h5>No Records ({orders.length - numOfOrdersWithRecords})</h5>
				</Box>
			</Box>
			<Box className={orderList}>
				{orders
					.filter(
						(order) =>
							(showOrdersWithRecords && !order.noRecordsFound) ||
							(!showOrdersWithRecords && order.noRecordsFound)
					)
					.map((order: IOrder) => (
						<OrderItem
							key={order['Order ID']}
							order={order}
							onClick={handleOrderClick(order['Order ID'])}
							onClickCompletedBadge={handleClickCompletedBadge(order)}
						/>
					))}
			</Box>
		</Box>
	);
};

export default OrderSelector;
