import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';

import App from './components/App/App';
import AppContextProvider from './components/AppContextProvider';
import reportWebVitals from './reportWebVitals';

import theme from './styles/theme';
import './index.css';
import SnackBarContextProvider from 'components/SnackBarContextProvider';

ReactDOM.render(
	<React.StrictMode>
		<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>
				<AppContextProvider>
					<SnackBarContextProvider>
						<App />
					</SnackBarContextProvider>
				</AppContextProvider>
			</ThemeProvider>
		</StylesProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
