import { lighten, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexRowStartStart, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...fullHeight,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		backgroundColor: lighten(theme.palette.custom.common.lightGray, .6)
	},
	content: {
		...fullWidth,
		...flexRowStartStart,
		height: 'calc(100% - 64px)',
		flex: 1,
	},
}));
