import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IRepo } from './IRepo';
import { requestLogger, responseLogger } from './Interceptors';
import { NetErrorManager } from './NetErrorManager';
import Endpoints from './Endpoints';
import IUser from 'models/User';
import IOrder, { SentOrderResponse } from 'models/Order';
import IFirstNameRule, {
	IUpdateFirstNameRuleRequest,
} from 'models/FirstNameRule';
import ordersToXML from 'util/ordersToXML';
import { ICompany } from 'models/Company';
import { Roles } from 'constants/roles';

class CRepo implements IRepo {
	config: AxiosRequestConfig;
	net: AxiosInstance;
	initialized = false;
	handleError?: (error: string) => void;

	constructor(axiosConfig: AxiosRequestConfig) {
		this.config = axiosConfig;
		this.net = axios.create();
		this.init();
	}

	async init() {
		this.config.baseURL = process.env.REACT_APP_API_BASE_URL;
		if (this.config.headers) this.config.headers['requesting-app'] = 'WEB';
		else this.config.headers = { 'requesting-app': 'WEB' };

		if (process.env.NODE_ENV !== 'production') {
			this.net.interceptors.request.use(requestLogger);
			this.net.interceptors.response.use(responseLogger, (e: any) =>
				NetErrorManager.handleError(e, this.handleError)
			);
		} else {
			this.net.interceptors.response.use(
				(response: AxiosResponse) => response,
				(e: any) => NetErrorManager.handleError(e, this.handleError)
			);
		}

		this.initialized = true;
	}

	async makeRequest<T>(
		config: any,
		handleError?: (error: string) => void
	): Promise<AxiosResponse<T>> {
		const request = { ...config, ...this.config };
		this.handleError = handleError;
		return this.net(request);
	}

	setAuthHeader(token: string | null) {
		if (token) {
			localStorage.setItem('preDiscover-jwt', token);
			this.net.defaults.headers.common['Authorization'] = token;
		} else localStorage.removeItem('preDiscover-jwt');
	}

	async register(
		firstName: string,
		lastName: string,
		email: string,
		password: string,
		companyId: string,
		licenseId: string,
		handleError: (error: string) => void
	): Promise<boolean> {
		const request = {
			...Endpoints.register,
			data: {
				firstName,
				lastName,
				email,
				password,
				companyId,
				licenseId,
			},
		};

		const response = await this.makeRequest(request, handleError);
		return response?.status === 200;
	}

	async login(
		email: string,
		password: string,
		handleError: (error: string) => void
	): Promise<boolean> {
		const request = {
			...Endpoints.login,
			data: {
				email,
				password,
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;
		this.setAuthHeader('bearer ' + data);

		return true;
	}

	logout() {
		this.setAuthHeader(null);
		window.location.reload();
	}

	async getUser(
		storeUser: (user: IUser) => void,
		storeUsers: (companyId: string, users: IUser[]) => void
	): Promise<IUser> {
		const endpoint = { ...Endpoints.getUser };

		const response = await this.makeRequest(endpoint);
		const data: IUser = response.data as IUser;

		if (data.roles.includes(Roles.COMPANY_ADMIN))
			await this.getUsers(data.company.id, storeUsers);
		storeUser(data);

		return data;
	}

	async getUsers(
		companyId: string,
		storeUsers: (companyId: string, users: IUser[]) => void
	): Promise<IUser[]> {
		const endpoint = { ...Endpoints.getUsers, params: { companyId } };

		const response = await this.makeRequest(endpoint);
		const data: any = response.data as IUser[];

		storeUsers(data[0].company?.id, data);

		return data;
	}

	async changePassword(
		userId: string,
		password: string,
		newPassword: string,
		handleError: (error: string) => void
	): Promise<IUser> {
		const request = {
			...Endpoints.changePassword,
			data: {
				userId,
				password,
				newPassword,
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;

		return data;
	}

	async inviteUser(
		companyId: string,
		email: string,
		licenseId: string,
		handleError: (error: string) => void
	): Promise<ICompany> {
		const request = {
			...Endpoints.inviteUser,
			data: {
				companyId,
				email,
				licenseId,
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;

		return data;
	}

	async resendInvite(
		companyId: string,
		licenseId: string,
		handleError: (error: string) => void
	): Promise<ICompany> {
		const request = {
			...Endpoints.resendInvite,
			data: {
				companyId,
				licenseId,
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;

		return data;
	}

	async revokeInvite(
		companyId: string,
		licenseId: string,
		handleError: (error: string) => void
	): Promise<ICompany> {
		const request = {
			...Endpoints.revokeInvite,
			data: {
				companyId,
				licenseId,
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;

		return data;
	}

	async deactivateLicense(
		companyId: string,
		licenseId: string,
		handleError: (error: string) => void
	): Promise<ICompany> {
		const request = {
			...Endpoints.deactivateLicense,
			data: {
				companyId,
				licenseId,
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;

		return data;
	}

	async reactivateLicense(
		companyId: string,
		licenseId: string,
		handleError: (error: string) => void
	): Promise<ICompany> {
		const request = {
			...Endpoints.reactivateLicense,
			data: {
				companyId,
				licenseId,
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;

		return data;
	}

	async sendOrders(
		orders: IOrder[],
		handleError: (error: string) => void
	): Promise<SentOrderResponse[]> {
		let endpoint = { ...Endpoints.sendOrders };

		let request = {
			...endpoint,
			data: {
				orders: ordersToXML(orders),
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;
		return data;
	}

	async updateRoles(
		userId: string,
		roles: Roles[],
		setUsers: (updatedUser: IUser) => void,
		handleError: (error: string) => void
	): Promise<IUser> {
		const request = {
			...Endpoints.updateRoles,
			data: {
				userId,
				roles,
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;
		setUsers(data as IUser);

		return data;
	}

	async registerCompany(
		companyName: string,
		email: string,
		numOfLicenses: number,
		delveUsername: string,
		delvePassword: string,
		delveUrl: string,
		handleError: (error: string) => void
	): Promise<ICompany> {
		const request = {
			...Endpoints.registerCompany,
			data: {
				name: companyName,
				email,
				numOfLicenses,
				permissions: [],
				delveInfo: {
					username: delveUsername,
					password: delvePassword,
					url: delveUrl,
				},
			},
		};

		const response = await this.makeRequest(request, handleError);
		return response.data as ICompany;
	}

	async getCompanies(
		storeCompanies: (companies: ICompany[]) => void
	): Promise<ICompany[]> {
		const endpoint = { ...Endpoints.getCompanies };

		const response = await this.makeRequest(endpoint);
		const data: any = response.data as ICompany[];

		storeCompanies(data);

		return data;
	}

	async updateCompany(
		company: ICompany,
		handleError: (error: string) => void
	): Promise<ICompany> {
		const request = {
			...Endpoints.updateCompany,
			data: {
				company,
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;

		return data;
	}

	async getAllFirstNameRules(
		storeFirstNameRules: (rules: IFirstNameRule[]) => void
	): Promise<IFirstNameRule[]> {
		const endpoint = Endpoints.getAllFirstNameRules;

		const response = await this.makeRequest(endpoint);
		const data: any = response.data as IFirstNameRule[];

		storeFirstNameRules(data);

		return data;
	}

	async updateFirstNameRule(
		ruleWithSteps: IUpdateFirstNameRuleRequest,
		handleError: (error: string) => void
	): Promise<IFirstNameRule> {
		const request = {
			...Endpoints.updateFirstNameRule,
			data: {
				...ruleWithSteps,
			},
		};

		const response = await this.makeRequest(request, handleError);
		const data: any = response.data;

		return data;
	}

	async createFirstNameRule(
		rule: IFirstNameRule,
		handleError: (error: string) => void
	): Promise<IFirstNameRule> {
		const request = {
			...Endpoints.createFirstNameRule,
			data: {
				...rule,
			},
		};

		const response = await this.makeRequest(request, handleError);
		return response.data as IFirstNameRule;
	}
}

export const Repo = new CRepo({ timeout: 30000 });
