import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	flexCol,
	flexRowSpaceBetweenCenter,
	flexRowStartEnd,
	fullWidth,
	fullHeight,
	flexRow,
} from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullHeight,
		...flexCol,
		flex: 1,
		overflow: 'hidden',
		padding: '16px 16px 0 16px',
		boxShadow: `2px 2px 5px ${theme.palette.custom.common.black}`,
		border: `1px solid ${theme.palette.custom.common.lightGray}`
	},
	heading: {
		...fullWidth,
		...flexCol,
		padding: '4px 8px',
		margin: 0,
		border: `1px solid ${theme.palette.custom.common.lightGray}`,
		zIndex: 5,
		backgroundColor: theme.palette.custom.common.white,
	},
	bottomHeadingRow: {
		...flexRowSpaceBetweenCenter,

		'& h4': {
			lineHeight: 'normal',
		},

		'& > div': {
			...flexRowStartEnd,
		},
	},
	orderInfo: {
		...fullWidth,
		...flexRowStartEnd,

		'& > *': {
			margin: '0 8px 4px 0',
		},
	},
	caseList: {
		...fullWidth,
		...flexRow,
		flexWrap: 'wrap',
		overflowY: 'auto',
		paddingTop: '16px',
	},
	includeOrderBtn: {
		margin: '0 0 4px 8px !important',
	},
	nextCaseBtn: {
		marginLeft: '8px !important',
	}
}));
