import { Link } from '@mui/material';
import React, { FC,  } from 'react';

import Tooltip from 'components/common/Tooltip';

import { useStyles } from './style';

interface Props {
	text: string;
}

const SpecialInstructionsTooltip: FC<Props> = ({ text }) => {
	const { link } = useStyles();

	return (
		<>
			{text && (
				<Tooltip title={<p>{text}</p>}>
					<Link underline='none' className={link}>Special Instructions</Link>
				</Tooltip>
			)}
		</>
	);
};

export default SpecialInstructionsTooltip;
