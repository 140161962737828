import ICase from 'models/Case';
import ICharge from 'models/Charge';
import IOrder from 'models/Order';

const isVerifiedBy = (thisCase: ICase, prop: string) =>
	thisCase.verifiedBy.includes(prop) ? 'YES' : 'NO';

const chargeToXML = (charge: ICharge) => `
<Count>
	<Offense>${charge['Offense']?.replaceAll('<', 'LESS THAN').replaceAll('>', 'GREATER THAN') ?? ''}</Offense>
	<CrimeType>${charge['Offense Level'] ?? ''}</CrimeType>
	<Statute></Statute>
	<Disposition>${charge['Disposition'] ?? ''}</Disposition>
	<Fine>${charge['Fine'] ?? ''}</Fine>
	<CourtFee>${charge['Court Fee'] ?? ''}</CourtFee>
	<Restitution>${charge['Restitution'] ?? ''}</Restitution>
	<Plea></Plea>
	<CourtDate>${charge['Next Court Date'] ?? ''}</CourtDate>
	<DispositionDate>${charge['Disposed On'] ?? ''}</DispositionDate>
	<Sentence>
		<CommunityService>${charge['Community Service'] ?? ''}</CommunityService>
		<Probation>${charge['Probation'] ?? ''}</Probation>
		<Jail>${charge['Jail Time'] ?? ''}</Jail>
		<Prison>${charge['Prison Time'] ?? ''}</Prison>
		<JailSuspendedTime>${charge['Jail Time Suspended'] ?? ''}</JailSuspendedTime>
		<JailCreditTime>${charge['Jail Credit Time'] ?? ''}</JailCreditTime>
		<PrisonSuspendedTime>${
			charge['Prison Time Suspended'] ?? ''
		}</PrisonSuspendedTime>
		<PrisonCreditTime>${charge['Prison Credit Time'] ?? ''}</PrisonCreditTime>
		<SentenceOther>${charge['Other Sentencing Details'] ?? ''}</SentenceOther>
	</Sentence>
	<OffenseComments></OffenseComments>
</Count>
`;

const caseToXML = (thisCase: ICase) => `<Record>
<VerifiedBy>
	<FirstName>${isVerifiedBy(thisCase, 'First Name')}</FirstName>
	<MiddleName>${isVerifiedBy(thisCase, 'Middle Name')}</MiddleName>
	<LastName>${isVerifiedBy(thisCase, 'Last Name')}</LastName>
	<DOB>${isVerifiedBy(thisCase, 'DOB')}</DOB>
</VerifiedBy>
<OnFileDetail>
	<FirstName>${thisCase['First Name'] ?? ''}</FirstName>
	<LastName>${thisCase['Last Name'] ?? ''}</LastName>
	<MiddleName>${thisCase['Middle Name'] ?? ''}</MiddleName>
	<DOB>${thisCase['DOB'] ?? ''}</DOB>
	<SSN>${thisCase['SSN'] ?? ''}</SSN>
	<Gender>${thisCase['Sex'] ?? ''}</Gender>
	<Ethnicity>${thisCase['Race'] ?? ''}</Ethnicity>
	<Address>${thisCase['Address'] ?? ''}</Address>
	<Aliases></Aliases>
	<AdditionalIDInfo>${thisCase['Additional ID Info'] ?? ''}</AdditionalIDInfo>
</OnFileDetail>
<CaseNum>${thisCase['Case #'] ?? ''}</CaseNum>
<Court>${thisCase['Type of Court'] ?? ''}</Court>
<ArrestDate>${thisCase['Arrest Date'] ?? ''}</ArrestDate>
<FileDate>${thisCase['Filing Date'] ?? ''}</FileDate>
<DispositionDate>${thisCase['Disposition Date'] ?? ''}</DispositionDate>
<OffenseDate>${thisCase['Offense Date'] ?? ''}</OffenseDate>
<ArrestingAgency></ArrestingAgency>
<CaseComments></CaseComments>
${thisCase.charges.map(chargeToXML).join('')}
</Record>`;

const orderToXML = (order: IOrder) => `
<Order>
	<Subject>
		<FirstName>${order['First Name'] ?? ''}</FirstName>
		<MiddleName>${order['Middle Name'] ?? ''}</MiddleName>
		<LastName>${order['Last Name'] ?? ''}</LastName>
		<Suffix></Suffix>
		<DOB>${order['DOB'] ?? ''}</DOB>
	</Subject>
	<Recommendation></Recommendation>
	<ReportLink></ReportLink>
	<OrderDetail ServiceCode="CountyCriminal" OrderId="${order['Order ID'] ?? ''}">
		${order.noRecordsFound ? '' : '<Status>RECORD</Status>'}
		<County>${order['County'].split(',')[0].trim()}</County>
		<State>${order['County'].split(',')[1].trim()}</State>
		<ClosingComments></ClosingComments>
		<Result>
			<CaseCount>${order.cases.length}</CaseCount>
			${order.cases.map(caseToXML).join('')}
		</Result>
	</OrderDetail>
</Order>`;

const ordersToXML = (orders: IOrder[]) =>
	orders.map((order: IOrder) => ({
		id: order['Order ID'],
		xml: `<?xml version="1.0" encoding="utf-8"?>
		<OrderXML>
			<Method>PUSH RESULTS</Method>
			{{authentication}}
			${orderToXML(order)}
		</OrderXML>`
					.replaceAll('\n', '')
					.replaceAll('\t', '')
					.replaceAll('&', '&amp;')
	}));

export default ordersToXML;
