import { createContext } from 'react';

export type SnackBarContextActions = {
	showSuccess: (text: string) => void;
	showError: (text: string) => void;
	showWarning: (text: string) => void;
	showInfo: (text: string) => void;
};

export const SnackBarContext = createContext({} as SnackBarContextActions);

export interface SnackBarContextProviderProps {
	children: React.ReactNode;
}