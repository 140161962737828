import { Theme, Button, ButtonProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		margin: `0 0 0 ${theme.spacing(1)}px`,
	}
}));

const ButtonOutlined: FC<ButtonProps> = ({ children, ...rest }) => {
	const { root } = useStyles();
	return (
		<Button variant='outlined' classes={{ root }} {...rest}>
			{children}
		</Button>
	);
};

export default ButtonOutlined;