import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Repo } from 'network/Repo';
import { containsInputErrors, createValidationMap } from 'util/formValidation';

import { useStyles } from './style';
import { useSnackBar } from 'components/SnackBarContextProvider/hooks';

const RegisterForm = () => {
	const snackBar = useSnackBar();
	const navigate = useNavigate();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [hasFirstNameError, setHasFirstNameError] = useState(false);
	const [hasLastNameError, setHasLastNameError] = useState(false);
	const [hasEmailError, setHasEmailError] = useState(false);
	const [hasPasswordError, setHasPasswordError] = useState(false);
	const {
		loginForm,
		input,
		loginBtn,
	} = useStyles();
	const { companyId = '', activationCode = '' } = useParams();

	const handleRegister = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		const validationMaps = [
			createValidationMap(firstName, setHasFirstNameError),
			createValidationMap(lastName, setHasLastNameError),
			createValidationMap(email, setHasEmailError),
			createValidationMap(password, setHasPasswordError),
		];

		if (containsInputErrors(validationMaps)) return;

		const userCreated = await Repo.register(
			firstName,
			lastName,
			email,
			password,
			companyId,
			activationCode,
			snackBar.showError,
		);

		if (userCreated) navigate(`/login/${email}`, { replace: true });
	};

	const handleChange = (
		setFn: (val: string) => void,
		setHasErrorFn: (val: boolean) => void
	) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setFn(e.target.value);
		setHasErrorFn(false);
	};

	return (
		<form className={loginForm} onSubmit={handleRegister}>
			<TextField
				variant='outlined'
				margin='dense'
				placeholder='First Name'
				value={firstName}
				error={hasFirstNameError}
				onInput={handleChange(setFirstName, setHasFirstNameError)}
				className={input}
			/>
			<TextField
				variant='outlined'
				margin='dense'
				placeholder='Last Name'
				value={lastName}
				error={hasLastNameError}
				onInput={handleChange(setLastName, setHasLastNameError)}
				className={input}
			/>
			<TextField
				variant='outlined'
				margin='dense'
				placeholder='Email'
				value={email}
				error={hasEmailError}
				onInput={handleChange(setEmail, setHasEmailError)}
				className={input}
				type='email'
			/>
			<TextField
				variant='outlined'
				margin='dense'
				placeholder='Password'
				value={password}
				error={hasPasswordError}
				onInput={handleChange(setPassword, setHasPasswordError)}
				className={input}
				type='password'
			/>
			<Button variant='outlined' type='submit' className={loginBtn}>
				Submit
			</Button>
		</form>
	);
};

export default RegisterForm;
