import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexColStartCenter, flexColCenterCenter, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...fullHeight,
		...flexColStartCenter,
	},
	form: {
		...flexColCenterCenter,
		width: '400px',
		padding: '10px'
	},
	input: {
		width: '279px',
		marginBottom: '16px',
		marginTop: '8px'
	},
	submitBtn: {
		'&.MuiButton-root': {
			marginTop: '8px'
		}
	},
}))