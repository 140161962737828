import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			maxHeight: 'calc(100vh - 100px)',
			marginTop: '80px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: ({ width }: { width?: string }) => width ? width : '600px',
			maxWidth: ({ width }: { width?: string }) => width ? width : '600px',
			'&>	.MuiDialogTitle-root': {
				paddingBottom: '0'
			},
			'&>	.MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root, form': {
				width: '100%'
			}
		}
	}),
);