import React, { FC } from 'react';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import IOrder from 'models/Order';

import StyledTableRow from 'components/common/StyledTableRow';
import StyledTableCell from 'components/common/StyledTableCell';

import { useStyles } from './style';

interface Props {
	orders: IOrder[];
}

const NoRecordOrderList: FC<Props> = ({ orders }) => {
	const { container } = useStyles();

	return (
		<Box className={container}>
			<h1>No Records ({`${orders.length}`})</h1>
			<TableContainer component={Paper}>
				<Table
					sx={{ minWidth: 700 }}
					size='small'
					aria-label='customized table'
				>
					<TableHead>
						<TableRow>
							<StyledTableCell>Order ID</StyledTableCell>
							<StyledTableCell align='left'>Name</StyledTableCell>
							<StyledTableCell align='left'>
								Client
							</StyledTableCell>
							<StyledTableCell align='left'>DOB</StyledTableCell>
							<StyledTableCell align='left'>
								Years to Search
							</StyledTableCell>
							<StyledTableCell align='left'>
								Report Reference #
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{orders.map((order) => (
							<StyledTableRow key={order['Order ID']}>
								<StyledTableCell component='th' scope='row'>
									{order['Order ID']}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{`${order['Last Name']}, ${order['First Name']} ${order['Middle Name']}`}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{order.Client}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{order.DOB}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{order['Years to Search']}
								</StyledTableCell>
								<StyledTableCell align='left'>
									{order['Report Reference Number']}
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default NoRecordOrderList;
