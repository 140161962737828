import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogProps } from '@mui/material';

import { useStyles } from './style';

type IModalState = {
	isOpen?: boolean,
	width?: string,
	close?: () => void,
	onClose?: () => void,
} & DialogProps


export const Modal: FC<IModalState | any> = ({ isOpen = false, onClose = () => { }, close = () => { }, width = '600px', children, ...rest }) =>
{
	const { paper } = useStyles({ width });
	const [initialized, setInitialized] = useState(false);
	useEffect(() => {
		if (!isOpen && initialized) onClose();
		if (!initialized) setInitialized(true);
	}, [isOpen]);

	return (
		<Dialog
			aria-labelledby="transition-Mui-title"
			aria-describedby="transition-modal-description"
			open={isOpen}
			onClose={() => onClose()}
			onEscapeKeyDown={rest.onEscapeKeyDown || close}
			onBackdropClick={rest.onBackdropClick || close}
			disableBackdropClick
			classes={{
				paper: paper
			}}
		
			{...rest}
		>
			{children}
		</Dialog>
	);
};

export default Modal;