import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexRowStartCenter, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	suggestion: {
		...fullWidth,
		...flexRowStartCenter,
		paddingLeft: '16px',

		'& > button': {
			marginRight: '8px',
		},
	},
	btn: {
		padding: '0 4px',
	}
}));
