import { FC } from 'react';
import { Link, Typography } from '@mui/material';

import IEvent from 'models/Event';

import { useStyles } from './style';


interface Params {
	doc: IEvent;
}

const Document: FC<Params> = ({ doc }) => {
	const { container } = useStyles();

	return <Link href={doc.Image} target='_blank' underline='none' className={container}><Typography>{doc.Date} - {doc.Type}</Typography></Link>;
}

export default Document;