import React from 'react';

import IUser from 'models/User';
import { ICompany } from 'models/Company';
import IFirstNameRule from 'models/FirstNameRule';

interface IAppContext {
	user: IUser | null;
	users: { [key: string]: IUser[] };
	companies: ICompany[];
	firstNameRules: IFirstNameRule[];
	handleSetUser: (user: IUser | null) => void;
	handleSetUsers: (companyId: string, users: IUser[]) => void;
	handleSetCompany: (company: ICompany) => void;
	handleSetCompanies: (companies: ICompany[]) => void;
	handleSetFirstNameRules: (rules: IFirstNameRule[]) => void;
}

export const AppContext = React.createContext<IAppContext>({
	user: null,
	users: {},
	companies: [],
	firstNameRules: [],
	handleSetUser: (user: IUser | null) => {},
	handleSetUsers: (companyId: string, users: IUser[]) => {},
	handleSetCompany: (company: ICompany) => {},
	handleSetCompanies: (companies: ICompany[]) => {},
	handleSetFirstNameRules: (rules: IFirstNameRule[]) => {},
});
