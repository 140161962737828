import { useStyles } from './style';

const RegisterError = () => {
	const {
		errorText,
	} = useStyles();

	return <h4 className={errorText}>Please use the link from your company's invitation email to register.</h4>;
};

export default RegisterError;
