import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	link: {
		marginRight: '8px !important',
		marginBottom: '4px !important',
		cursor: 'default',
		fontWeight: 500,
	},
}));
