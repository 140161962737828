import { lighten, Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexCol, fullHeight, fullWidth, flexRowStartCenter } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	halfWidthCol: {
		width: '50%',
		padding: '0 16px',
	},
	licenseListContainer: {
		minWidth: '400px',
		...flexCol,
	},
	newLicenseContainer: {
		...flexRowStartCenter
	},
	newLicenseForm: {
		...flexRowStartCenter,
		width: '520px',
		marginTop: '24px',
		paddingRight: '16px',

		'& > button': {
			width: '210px',
			height: '38px',
			margin: '4px 8px 0px 16px',

			'&:disabled': {
				width: '360px',
			}
		}
	},
	roleCell: {
		'& span': {
			fontSize: '14px',
		}
	}
}))