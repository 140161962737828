import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Table,
	Paper,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	FormControlLabel,
	Switch,
	Box,
} from '@mui/material';

import IFirstNameRule from 'models/FirstNameRule';
import { Repo } from 'network/Repo';
import isSuperAdmin from 'util/isSuperAdmin';

import SettingsPage from 'components/common/SettingsPage';
import { AppContext } from 'components/AppContextProvider/context';
import ButtonAction from 'components/common/ButtonAction';
import StyledTableCell from 'components/common/StyledTableCell';
import StyledTableRow from 'components/common/StyledTableRow';
import TextFieldOutlined from 'components/common/TextFieldOutlined';

import { useStyles } from './style';
const ONLY_WITH_SUGGESTIONS_STORAGE_KEY = 'PD_FIRST_NAME_FILTER_ONLY_WITH_SUGGESTIONS';

const FirstNameRulesPage = () => {
	const navigate = useNavigate();
	const { user, firstNameRules, handleSetFirstNameRules } = useContext(
		AppContext
	);
	if (!isSuperAdmin(user!)) {
		navigate('/');
	}

	const { filterContainer, tableContainer } = useStyles();
	const [displayedRules, setDisplayedRules] = useState(firstNameRules);
	const [onlyWithSuggestions, setOnlyWithSuggestions] = useState(localStorage.getItem(ONLY_WITH_SUGGESTIONS_STORAGE_KEY) === 'true');
	const [searchName, setSearchName] = useState('');

	useEffect(() => {
		Repo.getAllFirstNameRules(handleSetFirstNameRules);
	}, []);

	useEffect(() => {
		const passesSuggestionsFilter = (numOfSuggestedNames: number) =>
			(onlyWithSuggestions && numOfSuggestedNames > 0) ||
			!onlyWithSuggestions;

		setDisplayedRules(
			firstNameRules.filter(
				(rule) =>
					rule.name.indexOf(searchName.toUpperCase()) === 0 &&
					passesSuggestionsFilter(rule.suggestedNames.length)
			)
		);
	}, [firstNameRules, searchName, onlyWithSuggestions]);

	useMemo(() => {
		firstNameRules.sort((rule1, rule2) =>
			rule1.name > rule2.name ? 1 : -1
		);
	}, [firstNameRules]);

	const handleEditRule = (name: string) => () => {
		navigate(`/settings/first-name-rules/${name}`);
	};
	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
		setSearchName(e.target.value);
	const handleOnlyWithSuggestionsToggle = () => {
		localStorage.setItem(ONLY_WITH_SUGGESTIONS_STORAGE_KEY, (!onlyWithSuggestions).toString());
		setOnlyWithSuggestions(!onlyWithSuggestions);
	}
		

	return (
		<SettingsPage title='First Name Rules'>
			<Box className={filterContainer}>
				<Box style={{ width: '300px' }}>
					<TextFieldOutlined
						label='Search by name...'
						value={searchName}
						onInput={handleSearch}
					/>
				</Box>
				<FormControlLabel
					control={
						<Switch
							checked={onlyWithSuggestions}
							onChange={handleOnlyWithSuggestionsToggle}
						/>
					}
					label='Show only rules with suggestions'
					labelPlacement='start'
				/>
			</Box>
			<Box className={tableContainer}>
				<Table size='small' component={Paper}>
					<TableHead>
						<TableRow
							style={{
								borderBottom: '2px solid black',
							}}
						>
							<TableCell>Name</TableCell>
							<TableCell>Accepted Names</TableCell>
							<TableCell>Excluded Names</TableCell>
							<TableCell># of Suggested Names</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{displayedRules.map((rule: IFirstNameRule) => (
							<StyledTableRow key={rule.name}>
								<StyledTableCell>{rule.name}</StyledTableCell>
								<StyledTableCell>
									{rule.acceptedNames.join(', ')}
								</StyledTableCell>
								<StyledTableCell>
									{rule.excludedNames.join(', ')}
								</StyledTableCell>
								<StyledTableCell>
									{rule.suggestedNames.length}
								</StyledTableCell>
								<StyledTableCell>
									<ButtonAction
										onClick={handleEditRule(rule.name)}
									>
										Edit
									</ButtonAction>
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</Box>
		</SettingsPage>
	);
};

export default FirstNameRulesPage;
