import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

import IOrder from 'models/Order';

import Modal from 'components/common/Modal/Modal';
import TextFieldOutlined from 'components/common/TextFieldOutlined';
import ButtonSuccess from 'components/common/ButtonSuccess';
import ButtonOutlined from 'components/common/ButtonOutlined';

import exportOrders from 'util/exportOrders';

import { useStyles } from './style';

interface Props {
	isOpen: boolean;
	county: string;
	orders: IOrder[];
	onClose: () => void;
}

const ExportModal: FC<Props> = ({ isOpen, county, orders, onClose }) => {
	const [fileName, setFileName] = useState(`PreDiscover Export: ${county} ${new Date().getTime()}`);
	const { dialogContent, buttonContainer } = useStyles();

	useEffect(() => {
		const now = new Date();
		const dateTimeText = `${now.getMonth() + 1}-${now.getDate()}-${now.getFullYear()} ${now.getHours()}${now.getMinutes()}`
		if(isOpen) setFileName(`PreDiscover Export - ${county} (${dateTimeText}h)`);
	}, [isOpen]);

	const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setFileName(event.target.value);

	const handleSubmit = () => {
		exportOrders(orders, fileName);
		onClose();
	}

	return (
		<Modal isOpen={isOpen}>
			<DialogTitle>Export Orders</DialogTitle>
			<DialogContent classes={{ root: dialogContent }}>
				<TextFieldOutlined
					label='File Name'
					value={fileName}
					onChange={handleChange}
				/>
			</DialogContent>
			<DialogActions className={buttonContainer}>
				<ButtonOutlined onClick={onClose}>Cancel</ButtonOutlined>
				<ButtonSuccess onClick={handleSubmit}>Export</ButtonSuccess>
			</DialogActions>
		</Modal>
	);
};

export default ExportModal;
