import ICase from 'models/Case';
import ICharge from 'models/Charge';
import IOrder from 'models/Order';
import generateGuid from 'util/generateGuid';
import prefixDOBWithZero from './prefixDOBWithZero';

const getEarlierDate = (charge1: Partial<ICharge>, charge2: ICharge) => (
	property: string,
	propAlias?: string
): string => {
	const dateString1 = charge1[propAlias ?? property] as string;
	const dateString2 = charge2[property] as string;

	if (dateString1 && !dateString2) return dateString1 as string;
	if (!dateString1 && dateString2) return dateString2 as string;
	if (!dateString1 && !dateString2) return '';

	const date1 = new Date(dateString1 as string);
	const date2 = new Date(dateString2 as string);

	return (date1.getTime() < date2.getTime()
		? dateString1
		: dateString2) as string;
};

const INITIAL_DATA_FROM_CHARGES = {
	'Offense Date': '',
	'Arrest Date': '',
	'Disposition Date': '',
	'Type of Court': 'STATE',
};

const getDataFromCharges = (charges: ICharge[]): Partial<ICharge> =>
	charges.reduce(
		(acc, charge) => {
			const getEarlierDateFunc = getEarlierDate(acc, charge);
			acc['Offense Date'] = getEarlierDateFunc('Offense Date');
			acc['Arrest Date'] = getEarlierDateFunc('Arrest Date');
			acc['Disposition Date'] = getEarlierDateFunc(
				'Disposed On',
				'Disposition Date'
			);

			if (
				acc['Type of Court'] === 'SUPERIOR' ||
				charge['Offense Level'] === 'FELONY'
			)
				acc['Type of Court'] = 'SUPERIOR';

			return acc;
		},
		{ ...INITIAL_DATA_FROM_CHARGES }
	);

const getSex = (sex: string) => {
	switch (sex) {
		case 'M':
		case 'MALE':
			return 'MALE';
		case 'F':
		case 'FEMALE':
			return 'FEMALE';
		default:
			return '';
	}
};

const formatCase = (order: IOrder) => (thisCase: ICase) => {
	const namesSeparatedByComma = thisCase.Defendant.split(',');
	const namesAfterComma = namesSeparatedByComma[1].trim().split(' ');
	const names = {
		'First Name': namesAfterComma[0],
		'Middle Name':
			namesAfterComma.length > 1
				? namesAfterComma.slice(1).join(' ')
				: '',
		'Last Name': namesSeparatedByComma[0],
	};

	const isPartialDOB = thisCase['DOB'].includes('X') || thisCase['DOB'].includes('#');

	const DOB =
		thisCase.DOB && thisCase.DOB.trim() ? new Date(thisCase.DOB) : null;
	const caseDates = {
		'DOB Month': DOB && !isPartialDOB ? (DOB.getMonth() + 1).toString() : '',
		'DOB Day': DOB && !isPartialDOB ? DOB.getDate().toString() : '',
		'DOB Year': DOB ? DOB.getFullYear().toString() : '',
	};

	thisCase.DOB = thisCase.DOB
		? prefixDOBWithZero(thisCase.DOB)
		: thisCase.DOB;

	return {
		...thisCase,
		...names,
		...{
			'Additional ID Info':
				isPartialDOB
					? `PARTIAL DOB: ${thisCase['DOB']
							.replaceAll('X', '#')
							.replaceAll('x', '#')}`
					: '',
		},
		...caseDates,
		DL: thisCase.DL ?? '',
		SSN: thisCase.SSN ?? '',
		Sex: getSex(thisCase.Sex?.toUpperCase()),
		Race: thisCase.Race?.trim() ?? '',
		isIncluded: thisCase.isIncluded ?? false,
		...getDataFromCharges(thisCase.charges),
		verifiedBy: [
			...(names['First Name'] &&
			names['First Name'] === order['First Name']
				? ['First Name']
				: []),
			...(names['Middle Name'] &&
			names['Middle Name'] === order['Middle Name']
				? ['Middle Name']
				: []),
			...(names['Last Name'] && names['Last Name'] === order['Last Name']
				? ['Last Name']
				: []),
			...(thisCase['DOB'] && new Date(thisCase['DOB']).getTime() === new Date(order['DOB']).getTime()
				? ['DOB']
				: []),
			...(thisCase['SSN'] && thisCase['SSN'] === order['SSN']
				? ['SSN']
				: []),
		],
		key: generateGuid(),
		charges: thisCase.charges.map((charge) => {
			const disposition =
				charge['Disposition Method'] ||
				(thisCase.Status.toUpperCase() === 'OPEN' ? 'PENDING' : '');

			return {
				...charge,
				isIncluded: charge.isIncluded ?? !!disposition,
				Disposition: disposition,
				'Other Sentencing Details': charge.sentences.reduce(
					(acc, sentence, i) => {
						acc += `${sentence.Description.replaceAll(
							'\t',
							''
						).replaceAll('\n', '')}${
							i + 1 !== charge.sentences.length ? '\n' : ''
						}`;
						return acc;
					},
					''
				),
				key: generateGuid(),
			};
		}),
		events: thisCase.events?.map((event) => ({
			...event,
			key: generateGuid(),
		})),
	};
};

export default formatCase;
