import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexCol, flexRowStart, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	content: {
		...fullWidth,
		...flexRowStart,
		marginTop: '8px',
		overflowY: 'hidden',
	},
	column: {
		...flexCol,
		flex: 1,

		'&:nth-child(2)': {
			padding: '0 16px',
		}
	},
	namesList: {
		...fullWidth,
		flex: 1,
		overflowY: 'auto',
	}
}));
