import React, { FC } from 'react';
import { Box } from '@mui/material';

import IOrder from 'models/Order';

import OrderReview from './OrderReview';
import { useStyles } from './style';

interface Props {
	orders: IOrder[];
}

const RecordOrderList: FC<Props> = ({ orders }) => {
	const { container } = useStyles();

	return (
		<Box className={container}>
			<h1>Records ({`${orders.length}`})</h1>
			{orders.map((order) => (
				<OrderReview order={order} key={order['Order ID']} />
			))}
		</Box>
	);
};

export default RecordOrderList;
