import React, { FC } from 'react';
import { Box } from '@mui/material';

import HeaderBar from 'components/HeaderBar';

import { useStyles } from './style';
import { TabOption } from 'models/TabOption';
import Tabs from './Tabs';

interface Props {
	title: string;
	className?: string;
	actions?: JSX.Element[];
	tabs?: TabOption[];
}

const SettingsPage: FC<Props> = ({
	title,
	className,
	actions = [],
	tabs,
	children,
}) => {
	const { container, content, heading } = useStyles();
	const tabsSection = tabs ? <Tabs options={tabs} /> : '';
	return (
		<div className={container}>
			<HeaderBar />
			<Box className={content}>
				<h1 className={heading}>
					{title}
					{actions}
				</h1>
				{className ? (
					<Box className={className}>
						{tabsSection}
						{children}
					</Box>
				) : (
					<>
						{tabsSection}
						{children}
					</>
				)}
			</Box>
		</div>
	);
};

export default SettingsPage;
