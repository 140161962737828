import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import IUser from 'models/User';

import HomePage from 'components/pages/HomePage';
import LoginPage from 'components/pages/LoginPage';
import RegisterPage from 'components/pages/RegisterPage';
import RegisterError from 'components/pages/RegisterPage/RegisterError';
import RegisterForm from 'components/pages/RegisterPage/RegisterForm';
import CompanyPage from 'components/pages/CompanyPage';
import AccountPage from 'components/pages/AccountPage';

import CompaniesPage from 'components/pages/internal/CompaniesPage';
import CompanyEditPage from 'components/pages/internal/CompanyEditPage';
import CompanyRegisterPage from 'components/pages/internal/CompanyRegisterPage';
import FirstNameRulesPage from 'components/pages/internal/FirstNameRulesPage';
import FirstNameRuleEditPage from 'components/pages/internal/FirstNameRuleEditPage';

import PrivateRoute from './PrivateRoute';
import SuperAdminRoute from './SuperAdminRoute';
import OrderSubmissionRoute from './OrderSubmissionRoute';

interface Props {
	user: IUser | null;
}

const AppRoutes: FC<Props> = ({ user }) => (
	<Router>
		<Routes>
			<Route
				path='/'
				element={
					<OrderSubmissionRoute user={user}>
						<HomePage />
					</OrderSubmissionRoute>
				}
			/>
			<Route path='/login' element={<LoginPage />} />
			<Route path='/login/:email' element={<LoginPage />} />
			<Route path='/register' element={<RegisterPage />}>
				<Route index={true} element={<RegisterError />} />
				<Route path='*' element={<RegisterError />} />
				<Route
					path=':companyId/:activationCode'
					element={<RegisterForm />}
				/>
			</Route>
			<Route
				path='/settings/company'
				element={
					<PrivateRoute user={user}>
						<CompanyPage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/settings/account'
				element={
					<PrivateRoute user={user}>
						<AccountPage />
					</PrivateRoute>
				}
			/>
			<Route
				path='/settings/companies'
				element={
					<SuperAdminRoute user={user}>
						<CompaniesPage />
					</SuperAdminRoute>
				}
			/>
			<Route
				path='/settings/companies/:companyId'
				element={
					<SuperAdminRoute user={user}>
						<CompanyEditPage />
					</SuperAdminRoute>
				}
			/>
			<Route
				path='/settings/companies/new'
				element={
					<SuperAdminRoute user={user}>
						<CompanyRegisterPage />
					</SuperAdminRoute>
				}
			/>
			<Route
				path='/settings/first-name-rules'
				element={
					<SuperAdminRoute user={user}>
						<FirstNameRulesPage />
					</SuperAdminRoute>
				}
			/>
			<Route
				path='/settings/first-name-rules/:ruleName'
				element={
					<SuperAdminRoute user={user}>
						<FirstNameRuleEditPage />
					</SuperAdminRoute>
				}
			/>
		</Routes>
	</Router>
);

export default AppRoutes;
