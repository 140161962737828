import React, { FC } from 'react';
import {
	Box,
	Table,
	TableBody,
	TableRow,
	TableCell,
	FormControlLabel,
	Switch,
} from '@mui/material';

import ICharge from 'models/Charge';
import ISentence from 'models/Sentence';

import TextFieldOutlined from 'components/common/TextFieldOutlined';
import TextArea from 'components/common/TextArea';

import { useStyles } from './style';

interface Props {
	charge: ICharge;
	chargeNum: number;
	onChange: (
		property: string
	) => (newValue: string | boolean | ISentence[]) => void;
}

const Charge: FC<Props> = ({ charge, chargeNum, onChange }) => {
	const { chargeContainer } = useStyles();

	const handleChange = (property: string) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => onChange(property)(e.target.value);

	const handleTextAreaChange = (property: string) => (
		e: React.ChangeEvent<HTMLTextAreaElement>
	) => onChange(property)(e.target.value);

	const handleToggleInclude = () =>
		onChange('isIncluded')(!charge.isIncluded);

	return (
		<Box className={chargeContainer}>
			<h3>
				Charge {chargeNum}{' '}
				<FormControlLabel
					control={
						<Switch
							checked={charge.isIncluded}
							onChange={handleToggleInclude}
						/>
					}
					label='Included'
					labelPlacement='start'
				/>
			</h3>
			<TextFieldOutlined
				label='Charge'
				value={charge.Offense}
				onInput={handleChange('Offense')}
			/>
			<Table size='small'>
				<TableBody>
					<TableRow>
						<TableCell>
							<TextFieldOutlined
								label='Disposition'
								value={charge['Disposition'] ?? ''}
								onInput={handleChange('Disposition')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Offense Level'
								value={charge['Offense Level'] ?? ''}
								onInput={handleChange('Offense Level')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Disposition Date'
								value={charge['Disposed On'] ?? ''}
								onInput={handleChange('Disposed On')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Probation'
								value={charge['Probation'] ?? ''}
								onInput={handleChange('Probation')}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TextFieldOutlined
								label='Community Service'
								value={charge['Community Service'] ?? ''}
								onInput={handleChange('Community Service')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Fine'
								value={charge['Fine'] ?? ''}
								onInput={handleChange('Fine')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Court Fee'
								value={charge['Court Fee'] ?? ''}
								onInput={handleChange('Court Fee')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Restitution'
								value={charge['Restitution'] ?? ''}
								onInput={handleChange('Restitution')}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TextFieldOutlined
								label='Prison Time'
								value={charge['Prison Time'] ?? ''}
								onInput={handleChange('Prison Time')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Prison Credit Time'
								value={charge['Prison Credit Time'] ?? ''}
								onInput={handleChange('Prison Credit Time')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Prison Time Suspended'
								value={charge['Prison Time Suspended'] ?? ''}
								onInput={handleChange('Prison Time Suspended')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Next Court Date'
								value={charge['Next Court Date'] ?? ''}
								onInput={handleChange('Next Court Date')}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TextFieldOutlined
								label='Jail Time'
								value={charge['Jail Time'] ?? ''}
								onInput={handleChange('Jail Time')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Jail Credit Time'
								value={charge['Jail Credit Time'] ?? ''}
								onInput={handleChange('Jail Credit Time')}
							/>
						</TableCell>
						<TableCell>
							<TextFieldOutlined
								label='Jail Time Suspended'
								value={charge['Jail Time Suspended'] ?? ''}
								onInput={handleChange('Jail Time Suspended')}
							/>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<TextArea
				label='Other Sentencing Details'
				value={charge['Other Sentencing Details'] ?? ''}
				onChange={handleTextAreaChange('Other Sentencing Details')}
			/>
		</Box>
	);
};

export default React.memo(Charge);
