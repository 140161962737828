import { lighten, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexCol, flexRowSpaceBetween, fullHeight, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...flexCol,
		background: theme.palette.custom.common.white,
		margin: '6px 0',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: lighten(theme.palette.custom.common.lightGray, .6),
		},
		// border: `1px solid ${theme.palette.custom.common.lightGray}`,
		// borderRadius: '7px',
		boxShadow: `1px 1px 5px ${theme.palette.custom.common.darkGray}`,
	},
	heading: {
		...fullWidth,
		margin: 0,
		padding: '2px 4px',
		background: theme.palette.custom.common.purple.light,
		// background: `linear-gradient(90deg, ${theme.palette.custom.common.purple.primary}, ${theme.palette.custom.common.white})`,
		color: theme.palette.custom.common.white,
		direction: 'ltr',
		// borderTopRightRadius: '7px',
		// borderTopLeftRadius: '7px',
	},
	content: {
		...fullWidth,
		...fullHeight,
		...flexRowSpaceBetween,
		padding: '4px',
		direction: 'ltr',
	},
	completedBadge: {
		fontSize: '18px !important',
		color: theme.palette.custom.common.lightGray,
		border: `2px solid ${theme.palette.custom.common.lightGray}`,
		backgroundColor: 'white',
		borderRadius: '50%',
	},
	completedBadgeActivated: {
		color: 'green',
		border: '2px solid green',
	}
}));
