import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	chargeContainer: {
		padding: '0 16px 16px',
		marginTop: '16px',
		backgroundColor: theme.palette.custom.common.white,
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: '7px',
	},
}));
