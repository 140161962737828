import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { flexCol, flexRow, flexRowStartEnd, fullWidth } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		...fullWidth,
		...flexCol,
		flex: 1,
		padding: '8px',
	},
	heading: {
		...flexRowStartEnd,
		paddingBottom: '8px',

		'& > h2': {
			margin: 0,
		},

		'& > a': {
			marginLeft: '8px',
			fontSize: '12px',
		}
	},
	content: {
		...flexRow,
		padding: '0 8px',
	},
	orderInfo: {
		maxWidth: '340px',
		padding: '0 8px',
	},
	caseInfo: {
		...flexCol,
		flex: 1,
	}
}))