import { styled } from '@mui/material/styles';
import ButtonOutlined from '../ButtonOutlined';

const ButtonAction = styled(ButtonOutlined)(({ theme }) => ({
	'&.MuiButton-outlined': {
		backgroundColor: theme.palette.custom.common.white,
		border: `1px solid ${theme.palette.custom.common.purple.primary}`,
		color: theme.palette.custom.common.purple.primary,

		'&:hover': {
			backgroundColor: theme.palette.custom.common.purple.light,
			borderColor: theme.palette.custom.common.purple.dark,
			color: theme.palette.custom.common.white,
		},

		'&:focus': {
			boxShadow: `0 0 0 1px ${theme.palette.custom.common.purple.light}`,
		},

		'&:active': {
			backgroundColor: theme.palette.custom.common.purple.dark,
			borderColor: theme.palette.custom.common.purple.dark,
		},

		'&:disabled': {
			backgroundColor: theme.palette.custom.common.lightGray,
			borderColor: theme.palette.custom.common.lightGray,
			color: theme.palette.custom.common.darkGray,
			cursor: 'not-allowed',
		},
	},
}));

export default ButtonAction;