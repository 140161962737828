import React, { FC } from 'react';
import { Box, Paper, Table, TableBody, TableContainer } from '@mui/material';

import IOrder from 'models/Order';

import { useStyles } from './style';
import SpecialInstructionsTooltip from 'components/pages/HomePage/SpecialInstructionsTooltip';
import StyledTableCell from 'components/common/StyledTableCell';
import StyledTableRow from 'components/common/StyledTableRow';

import CaseReview from './CaseReview';

interface Props {
	order: IOrder;
}

const OrderReview: FC<Props> = ({ order }) => {
	const { container, heading, content, orderInfo, caseInfo } = useStyles();
	const {
		'Order ID': orderId,
		Client: client,
		County: county,
		'Report Reference Number': reportRefNum,
		DOB: dob,
		cases
	} = order;

	return (
		<Box className={container}>
			<Box className={heading}>
				<h2>
					{`${order['Last Name']}, ${order['First Name']} ${order['Middle Name']}`}{' '}
				</h2>
				<SpecialInstructionsTooltip
					text={order['Special Instructions']}
				/>
			</Box>
			<Box className={content}>
				<Box className={orderInfo}>
					<TableContainer component={Paper}>
						<Table
							size='small'
							aria-label='customized table'
						>
							<TableBody>
								<StyledTableRow>
									<StyledTableCell component='td' scope='row' width={170}>
										<strong>Order ID:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{orderId}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component='td' scope='row' width={170}>
										<strong>DOB:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{dob}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component='td' scope='row' width={170}>
										<strong>Client:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{client}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component='td' scope='row' width={170}>
										<strong>County:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{county}
									</StyledTableCell>
								</StyledTableRow>
								<StyledTableRow>
									<StyledTableCell component='td' scope='row' width={170}>
										<strong>Report Reference #:</strong>
									</StyledTableCell>
									<StyledTableCell component='td' scope='row'>
										{reportRefNum}
									</StyledTableCell>
								</StyledTableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
				<Box className={caseInfo}>
					{
						cases.map((thisCase, i) => <CaseReview case={thisCase} caseIndex={i} key={thisCase.key} />)
					}
				</Box>
			</Box>
		</Box>
	);
};

export default OrderReview;
