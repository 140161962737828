import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { flexCol, fullWidth, flexRowEndCenter, flexRowSpaceBetweenCenter } from 'styles/stylesLib';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		flex: 1,
		backgroundColor: theme.palette.custom.common.white,
		overflowY: 'auto',
		paddingBottom: '16px',

		'& .heading': {
			position: 'sticky',
			zIndex: 50,
			top: 0,
		},
	},
	list: {
		...fullWidth,
		...flexCol,
		padding: '0 32px',
	},
	headingContainer: {
		...flexRowSpaceBetweenCenter,
		width: '100%',
		padding: '8px',
		marginBottom: '12px',
		border: `1px solid ${theme.palette.custom.common.lightGray}`,
		boxShadow: '2px 2px 5px black',
		backgroundColor: theme.palette.custom.common.lightGray,
	},
	heading: {
		margin: 0,
	},
	actionsContainer: { 
		...flexRowEndCenter
	},
	includeSwitch: {
		paddingLeft: '16px',
	},
	documentBtnContainer: {
		...fullWidth,
		...flexRowSpaceBetweenCenter,
		padding: '8px 16px 8px 8px'
	},
	documentLinksContainer: {
		...flexCol,
	},
	maxWidth1000: {
		maxWidth: '1000px',
	},
	unsavedMsg: {
		margin: '0 8px 0 0',
		color: 'red'
	},
}));
