export enum CountyPermissions {
	DEKALB = 'DEKALB',
	FULTON = 'FULTON',
	GWINNETT = 'GWINNETT',
	MUSCOGEE = 'MUSCOGEE',
	// SPALDING = 'SPALDING', County Unavailable - Maybe permanently
	DOUGLAS = 'DOUGLAS',
	CRAWFORD = 'CRAWFORD',
	FAYETTE = 'FAYETTE',
	FLOYD = 'FLOYD',
	HABERSHAM = 'HABERSHAM',
	TROUP = 'TROUP',
	CANDLER = 'CANDLER',
	HART = 'HART',
	DODGE = 'DODGE',
	GRADY = 'GRADY',
	PICKENS = 'PICKENS',
	WHITFIELD = 'WHITFIELD',
	CHATTAHOOCHEE = 'CHATTAHOOCHEE',
	CLARKE = 'CLARKE',
	FRANKLIN = 'FRANKLIN',
	JACKSON = 'JACKSON',
	MILLER = 'MILLER',
	MORGAN = 'MORGAN',
	TAYLOR = 'TAYLOR',
	LEE = 'LEE',
	BURKE = 'BURKE',
	CHATHAM = 'CHATHAM',
}

export enum GeneralPermissions {
	ADMIN = 'ADMIN',
	MATCH_EXISTENCE = 'MATCH_EXISTENCE',
	FULL_DATA = 'FULL_DATA',
	ORDER_SUBMISSION_APP = 'ORDER_SUBMISSION_APP',
}

export const CompanyPermissions = {
	...CountyPermissions,
	...GeneralPermissions,
};
